import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Mapview from '../views/Mapview.vue'
import Login from '../views/Login.vue'
import NotFound from '../views/NotFound.vue'
import SlideList from '@/views/SlideList'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Mapview,
    meta: { public: true }
  },
  {
    path: '/villes/:city',
    name: 'villes',
    component: Home,
    meta: { public: true }
  },
  {
    path: '/villes/:city/:election',
    name: 'villes-election',
    component: Home,
    meta: { public: true }
  },
  {
    path: '/villes/:city/:election/slide/:slideType',
    name: 'slideType',
    component: Home
  },
  {
    path: '/villes/:city/:election/slide/:slideType/:slideIndex',
    name: 'slideIndex',
    component: Home
  },
  {
    path: '/liste-diapositives/:city',
    name: 'SlideList',
    component: SlideList
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { public: true }
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  // base: process.env.BASE_URL,
  routes
})

export default router
