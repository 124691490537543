<template>
  <div>
    <svg
      viewBox="0 0 407 427"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 59.1 (86144) - https://sketch.com -->
      <title>Path 2</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Responsive"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="mairie_desktop_1440"
          transform="translate(-57.000000, -268.000000)"
          :fill="color"
        >
          <path
            id="Path-2"
            d="M181.530052,682.161305 C142.006643,670.973147 109.974617,651.321295 85.4339736,623.20575 C60.89333,595.090205 52.2379931,554.761266 59.4679629,502.218932 C66.5886281,456.90415 93.8433751,420.393748 141.232204,392.687725 C212.315448,351.12869 244.064905,336.422964 278.74972,305.181219 C313.434535,273.939475 373.580267,260.467149 404.303672,273.324095 C435.027077,286.181041 458.607779,318.896527 462.193675,337.439793 C465.779571,355.983058 465.041765,400.982369 450.513069,430.832246 C440.827272,450.732164 428.831939,470.846628 414.527071,491.175637 C400.446572,512.182464 389.746617,541.698616 382.427207,579.724094 C375.107796,617.749571 363.822562,645.991697 348.571506,664.450472 C327.397193,686.563402 298.040024,696.464938 260.5,694.155082 C222.959976,691.845225 196.63666,687.8473 181.530052,682.161305 Z"
          ></path>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'green'
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  width: 26.45rem;
  height: 27.68rem;
  @media (max-height: 1024px) {
  }
  @media (max-height: 899px) {
    width: 17rem;
    height: 21rem;
  }
}
</style>
