import {
  displayNum,
  getCandidatById,
  mairieCandidatesBlackOrWhite
} from './utils'
export default (results, params) => () => {
  const mairieResults = results.resultats_postes.find(
    elem => elem.poste_id === 1
  )

  const candidats = mairieResults.resultats_candidats.map(elem => {
    const candidat = getCandidatById(params, elem.candidat_id)
    const defaultColor = mairieCandidatesBlackOrWhite(params)
    return {
      label: candidat.candidat_label,
      color: defaultColor ? '#2d9d8e' : '#' + candidat.candidat_couleur,
      value: elem.votes_taux,
      valueLabel: displayNum(elem.votes_taux) + ' %'
    }
  })

  const slideContent = {
    type: 's5',
    description:
      'S5: Mairie — votes dépouillées et validés par chaque candidat',
    candidats
  }
  const title = params.postes[0].poste_label
  return {
    type: 's5',
    template: 's5Votes',
    data: { slideContent, title }
  }
}
