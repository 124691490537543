<template>
  <div class="marquee">
    <div class="track">
      <div class="track__content">
        <span v-for="div in divs" :key="div" class="wrapper">
          <div class="track__content__title">
            <div>{{ poste.toUpperCase() }} :</div>
          </div>
          <banner-element
            v-for="(item, index) in items"
            :key="index"
            :name="item.name"
            :color="item.color"
            :taux="item.taux + ' %'"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import BannerElement from '@/components/banner-element'
export default {
  components: {
    BannerElement
  },
  props: {
    poste: {
      type: String,
      default: 'test'
    },
    items: {
      type: Array,
      default: () => [
        { color: 'blue', name: 'Candidat Un', taux: 65.3 },
        { color: 'chartreuse', name: 'Candidat Deux', taux: 72.44 },
        { color: 'lime', name: 'Candidat Trois', taux: 47.26 },
        { color: 'grey', name: 'Candidat Quatre', taux: 58.34 },
        { color: 'red', name: 'Candidat Cinq', taux: 32.5 }
      ]
    }
  },
  setup(props) {
    return {
      // double the items to avoid whitespace
      divs: 12
    }
  }
}
</script>

<style lang="scss" scoped>
.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
  .wrapper {
    display: flex;
  }
}

.track {
  // position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 12s linear infinite;
  height: 6.25rem;
  &__content {
    display: flex;
    align-items: center;
    height: 100%;
    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'SF Pro Bold';
      // padding: 0 10rem;
      font-size: 1.5rem;
      margin-right: 2.5rem;
    }
  }
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
