<template>
  <div class="candidat">
    <div
      class="candidat__image"
      :style="{ backgroundImage: `url(${image})` }"
    ></div>
    <div class="candidat__name">{{ name }}</div>
    <div class="candidat__votes">
      <div class="candidat__votes__label">— VOTES —</div>
      <div class="candidat__votes__value">{{ votes }}</div>
    </div>
    <div class="candidat__taux">{{ taux }} %</div>
  </div>
</template>

<script>
const defaultData = {
  image: 'http://localhost:8080/json-test/010102.jpg',
  name: 'Prénom Nom-1',
  votes: '12 345',
  taux: '61,14'
}
export default {
  props: {
    image: {
      type: String,
      default: defaultData.image
    },
    name: {
      type: String,
      default: defaultData.name
    },
    votes: {
      type: String,
      default: defaultData.votes
    },
    taux: {
      type: String,
      default: defaultData.taux
    }
  },
  setup() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.candidat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__image {
    margin-top: -2.312rem;
    width: 9.375rem;
    height: 9.375rem;
    border-radius: 3.125rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    @media (max-height: 1024px) {
    }
    @media (max-height: 899px) {
      width: 6.188rem;
      height: 6.188rem;
      border-radius: 2rem;
    }
  }

  &__name {
    margin: 0.875rem 0;
    font-family: 'SF Pro Bold';
    font-size: 2rem;
    @media (max-height: 1024px) {
    }
    @media (max-height: 899px) {
      font-size: 1.5rem;
    }
    color: #2d2d2d;
  }
  &__votes {
    margin-bottom: 1.5rem;
    font-family: 'SF Pro Bold';
    font-weight: 700;
    font-size: 1rem;
    @media (max-height: 1024px) {
    }
    @media (max-height: 899px) {
      font-size: 0.75rem;
    }
    color: #919191;
    &__value {
      font-size: 1.625rem;
      @media (max-height: 1024px) {
      }
      @media (max-height: 899px) {
        font-size: 1.125rem;
      }
      color: #2d2d2d;
      padding-top: 0.2rem;
    }
  }
  &__taux {
    font-size: 2.625rem;
    @media (max-height: 1024px) {
    }
    @media (max-height: 899px) {
      font-size: 2rem;
    }
    color: #2d2d2d;
    letter-spacing: 0.05rem;
  }
}
</style>
