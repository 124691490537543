<template>
  <div class="slide-list">
    <h1>{{ location }}: Liste des diapositives (pour test)</h1>
    <div class="slide-list__content">
      <div v-for="(slideType, index) in slides" :key="index">
        <div v-if="Array.isArray(slideType)">
          {{ slideType[0].description }}
          <ul>
            <li v-for="(item, i) in slideType" :key="i">
              <a
                target="_blank"
                :href="`/villes/${location}/${item.type}/${i + 1}`"
                >diapositive {{ i + 1 }}</a
              >
            </li>
          </ul>
        </div>
        <div v-else>
          {{ slideType.description }}:
          <a target="_blank" :href="`/villes/${location}/${slideType.type}`"
            >diapositive</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from '@vue/runtime-core'
import { ref } from '@vue/reactivity'
import { getDataFile } from '@/lib/apiClient'
import { getSlides } from '@/lib/dataTransformer'
export default {
  name: 'Home',
  components: {},
  setup() {
    const route = useRoute()
    const location = route.params.city || 'granby'
    const params = ref(null)
    const participation = ref(null)
    const config = ref(null)
    const results = ref(null)
    const dataReady = ref(false)
    const slides = computed(() =>
      dataReady.value === true
        ? getSlides(
            params.value,
            results.value,
            participation.value,
            config.value,
            location,
            null,
            null,
            false
          )
        : null
    )
    onMounted(async () => {
      params.value = await getDataFile('parametres', location)
      results.value = await getDataFile('resultats', location)
      participation.value = await getDataFile('participation', location)
      config.value = await getDataFile('config', location)
      dataReady.value = true
    })
    return {
      slides,
      location
    }
  }
}
</script>
<style lang="scss">
.slide-list {
  margin: 40px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
  line-height: 140%;
  &__content {
    padding: 20px;
  }
}
</style>
