export function displayNum(val, lang = 'fr') {
  return new Intl.NumberFormat(`${lang}-CA`, {
    maximumFractionDigits: 2
  }).format(val)
}

export function getCandidatById(params, candidatId) {
  const candidat = params.candidats.find(c => c.candidat_id === candidatId)
  if (!candidat) {
    throw new Error('can not find candidate in params with id ' + candidatId)
  }
  return candidat
}

export function mairieCandidatesBlackOrWhite(params) {
  if (
    params.candidats
      .filter(c => c.poste_id === 1)
      .every(
        c =>
          c.candidat_couleur === '000000' ||
          c.candidat_couleur.toUpperCase() === 'FFFFFF'
      )
  ) {
    return true
  } else return false
}
