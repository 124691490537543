export default participation => () => {
  const voteheure = participation.repartition_heures.map(vote => {
    return {
      heure: vote.heure_label.replace('<', ''),
      nbrvote: vote.quantite
    }
  })
  const slideContent = {
    type: 's14',
    yaxisname: 'NOMBRE DE VOTES',
    color: '#1f74c1',
    valueFormatter: val => val,
    percent: false,
    nombrevote: voteheure
  }
  const title = 'L’ÉLECTION EN CHIFFRES'
  return {
    type: 's14',
    description:
      'S14: Graphique: nombre de votes aux tables d’accueil dans le temps',
    template: 'ElectionSlide14',
    data: { slideContent, title }
  }
}
