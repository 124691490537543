import _ from 'lodash'
import chroma from 'chroma-js'
import { getCandidatById } from './utils'
import {
  candidatDistrictsChoropleth,
  buildDomainSteps
} from '@/lib/statsHelper'
export default (results, params) => () => {
  function getSlide(candidatId, candidatvotes) {
    const candidat = getCandidatById(params, candidatId)
    if (!candidat) {
      throw new Error('can not find candidat with id ' + candidatId)
    }
    const districtsbycandidat = candidatvotes.map(candidat => {
      return {
        district_id: candidat.District.District,
        votes_taux: candidat.District.votes_taux
      }
    })
    // creating the  legend
    const f = chroma.scale(['white', candidat.candidat_couleur])
    const colors = [f(0.33), f(0.66), f(0.99)].map(c =>
      c.hex('rgb').toUpperCase()
    )
    const listevotetaux = districtsbycandidat.map(elem => elem.votes_taux)
    const getminmaxvotetaux = [
      Math.min(...listevotetaux),
      Math.max(...listevotetaux)
    ]
    const domaincolors = buildDomainSteps(colors, getminmaxvotetaux)
    // creating the district colors for the map
    const districtsfors8 = candidatDistrictsChoropleth(
      districtsbycandidat,
      colors,
      '#cfcfcf',
      'quantize'
    )
    const slideContent = {
      type: 's8',
      candidats: candidat.candidat_label,
      district: candidatvotes,
      legendvalues: domaincolors
    }
    const mapContent = {
      districtsfors8
    }

    const title = 'Mairie: Candidats'
    const data = { slideContent, title, mapContent }

    return {
      type: 's8',
      description: 'S8: Mairie — Candidat X — votes recueillis par district',
      template: 'MapSlide',
      data
    }
  }
  const mairiefilter = results.resultats_postes_districts.filter(elem =>
    elem.poste_label.includes('Maire')
  )
  // get list of taux de votes by candidat by district
  const candidatalive = mairiefilter.map(elem =>
    elem.resultats_candidats.map(element => {
      return {
        Candidat: element.candidat_id,
        District: {
          District: elem.district_id,
          votes_taux: element.votes_taux
        }
      }
    })
  )
  const grouped = _.groupBy(candidatalive.flat(), 'Candidat') // group the taux de votes by candidat
  const slides = Object.keys(grouped).map(key => getSlide(key, grouped[key]))
  return slides
}
