import { getCandidatById } from './utils'
function getCandidat(params, id) {
  try {
    return getCandidatById(params, id)
  } catch (error) {
    return null
  }
}
export default (results, params) => {
  function getSlide(districtId) {
    const district = params.districts.find(
      d => parseInt(d.district_id) === parseInt(districtId)
    )
    if (!district) {
      throw new Error('can not find district label for id' + districtId)
    }
    const mairePoste = params.postes.find(p => p.poste_label.includes('Maire'))
    const sectionsResults = results.resultats_postes_sections.filter(
      res =>
        res.district_id === districtId && res.poste_id !== mairePoste?.poste_id
    )
    const districtSectionIds = params.sections
      .filter(s => s.district_id === districtId)
      .map(s => s.section_id)
    const sections = districtSectionIds
      .map(sectionId => {
        const selectedResult = sectionsResults
          .filter(r => r.section_id === sectionId)
          .reduce(
            (acc, current) =>
              acc && acc.avance > current.avance ? acc : current,
            null
          )
        if (!selectedResult) {
          return null
        }
        const candidat = getCandidat(params, selectedResult.candidat_id)

        if (!candidat) {
          return null
        }
        return {
          name: candidat.candidat_label,
          color: `#${candidat.candidat_couleur}`,
          candidat_id: candidat.candidat_id,
          candidat_label: candidat.candidat_label,
          section_id: selectedResult.section_id
        }
      })
      .filter(item => Boolean(item))
    const distinctCandidats = sections.reduce((candidats, current) => {
      if (
        candidats.find(candidat => candidat.candidat_id === current.candidat_id)
      ) {
        return candidats
      }
      const candidat = {
        candidat_id: current.candidat_id,
        color: current.color,
        name: current.candidat_label
      }
      return [...candidats, candidat]
    }, [])
    const mapContent = {
      candidatsdata: sections,
      district: districtId,
      sectionIds: params.sections
        .filter(s => s.district_id === districtId)
        .map(s => s.section_id)
    }
    const slideContent = {
      type: 's9',
      candidats: distinctCandidats
    }
    const depouillementDistrict = results.depouillement_districts.find(
      district => district.district_id === districtId
    )
    if (!depouillementDistrict) {
      throw new Error(
        'can not find depouillement district for district ' + districtId
      )
    }

    const scrutin = depouillementDistrict.boite_de_scrutin
    const title = `DISTRICT ${district.district_label}: CANDIDATS`
    const data = { slideContent, scrutin, title, mapContent }
    return {
      type: 's9',
      description: 'S9: District — Candidat en avance par section de vote',
      template: 'MapSlide',
      data
    }
  }
  return () =>
    params.districts
      .filter(d => d.district_id !== 9999) // TODO: remove once problem solved (district in parametres but not in geojson)
      .map(({ district_id: id }) => getSlide(parseInt(id)))
}
