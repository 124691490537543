<template>
  <div>
    <gl-map
      ref="mapContainer"
      :init-map-options="basemap === 'satellite' ? options : null"
      :element-style="elementStyle"
    />
  </div>
</template>

<script>
import GlMap from '@anagraph/gl-map'
import mapboxgl from 'mapbox-gl'
import { shallowRef, onMounted, watch } from 'vue'
import { boundingBox } from '../commons/composable/boundingBox'

export default {
  components: {
    GlMap
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    district: {
      type: Object,
      default: () => null
    },
    section: {
      type: Object,
      default: () => null
    },
    selected: {
      type: [String, Boolean],
      default: null
    },
    data: {
      type: Object,
      default: () => null
    },
    basemap: {
      type: String,
      default: null
    }
  },
  setup(props, { emit }) {
    mapboxgl.accessToken =
      'pk.eyJ1IjoiY29kZWxmIiwiYSI6ImNrcmduYnhvNDRiNDgyd24zaXRvbGt3b2YifQ.1U02O0sTFxfQOuuVct55bg'
    const options = { style: 'mapbox://styles/mapbox/satellite-v9' }
    const getFontSize = () =>
      parseInt(
        window
          .getComputedStyle(document.querySelector('html'))
          .fontSize.split('px')[0]
      )
    const getMapSize = () => {
      const viewportHeight = window.innerHeight
      if (viewportHeight <= 899) {
        return {
          width: '39.33rem',
          height: '25.375rem'
        }
      }
      if (viewportHeight <= 1024) {
        return {
          width: '52.31rem',
          height: '33.75rem'
        }
      }
      return {
        width: '58.125rem',
        height: '37.5rem'
      }
    }
    const elementStyle = {
      ...getMapSize()
    }
    const mapContainer = shallowRef(null)
    onMounted(async () => {
      const map = mapContainer.value.map
      map.on('load', () => {
        // add section layer
        map.addSource('sections', {
          type: 'geojson',
          data: props.section
        })
        // Add a new layer to visualize the polygon.
        map.addLayer({
          id: 'section',
          type: 'fill',
          source: 'sections', // reference the data source
          layout: {
            visibility: 'none'
          },
          paint: {
            'fill-color': '#CFCFCF',
            'fill-opacity': 0.7
          }
        })
        // add new layer districts
        map.addSource('districts', {
          type: 'geojson',
          data: props.district
        })
        // Add a new layer to visualize the polygon.
        map.addLayer({
          id: 'district',
          type: 'fill',
          source: 'districts', // reference the data source
          layout: {
            visibility: 'none'
          },
          paint: {
            'fill-color': 'white',
            'fill-opacity': 0.5
          }
        })
        // Add a black outline around the polygon.
        map.addLayer({
          id: 'district-borders',
          type: 'line',
          source: 'districts',
          layout: {
            visibility: 'none'
          },
          paint: {
            'line-color': '#000',
            'line-width': 1
          }
        })
        map.addLayer({
          id: 'section-borders',
          type: 'line',
          source: 'sections',
          layout: {
            visibility: 'none'
          },
          paint: {
            'line-color': 'white',
            'line-width': 1
          }
        })
        // Add text label
        map.addLayer({
          id: 'symbols',
          type: 'symbol',
          source: 'districts',
          layout: {
            'symbol-placement': 'point',
            // 'text-font': ['Open Sans Regular'],
            'text-field': ['get', 'district_label'],
            'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
            'text-radial-offset': 0.5,
            'text-justify': 'auto',
            'text-size': getFontSize() * 0.85,
            visibility: 'none'
          },
          paint: {
            'text-color': '#000',
            'text-halo-color': '#FFF',
            'text-halo-width': (getFontSize() / 16) * 1.5 // 1px pour screen width 1600px, 2px pour screen width 3200px
          }
        })
        map.addLayer({
          id: 'section-labels',
          type: 'symbol',
          source: 'sections',
          layout: {
            'symbol-placement': 'point',
            // 'text-font': ['Open Sans Regular'],
            'text-field': ['get', 'section'],
            'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
            'text-radial-offset': 0.5,
            'text-justify': 'auto',
            'text-size': getFontSize() * 0.85,
            visibility: 'none'
          },
          paint: {
            'text-color': '#000',
            'text-halo-color': '#FFF'
            // 'text-halo-width': (getFontSize() * 0.5) / 16 // 1px pour screen width 1600px, 2px pour screen width 3200px
          }
        })
        emit('loaded', true)
      })
    })
    watch(
      () => props.selected,
      select => {
        const map = mapContainer.value.map
        console.log('props selected live up: ', props.selected)
        const filter = e => e.properties.district === props.selected
        const bbTool = boundingBox(props.district, map, getFontSize())
        if (props.selected) {
          map.setFilter('district', ['==', 'district', select])
          map.setFilter('district-borders', ['==', 'district', select])
          bbTool.fitToFilter(filter)
        } else {
          map.setFilter('district', ['all'])
          map.setFilter('district-borders', ['all'])
          bbTool.fitToAll()
        }
      }
    )
    watch(
      () => props.data,
      newVal => {
        const map = mapContainer.value.map
        function visnone() {
          const list = Array.from(arguments)
          list.forEach(elem => {
            map.setLayoutProperty(elem, 'visibility', 'none')
          })
        }
        function visvisible() {
          const list = Array.from(arguments)
          list.forEach(elem => {
            map.setLayoutProperty(elem, 'visibility', 'visible')
          })
        }
        if (newVal?.candidatsEnAvanceParDistrict) {
          visnone('section')
          visvisible('district', 'district-borders', 'symbols')
          map.setPaintProperty('district-borders', 'line-color', '#ffffff')
          map.setPaintProperty('district-borders', 'line-width', 1)
          const listecolor = newVal.candidatsEnAvanceParDistrict.map(
            element => {
              return [element.district_id, element.color || 'white']
            }
          )
          listecolor.push('white') // push a last color to get even number for mapbox style
          map.setPaintProperty('district', 'fill-color', [
            'match',
            ['get', 'district'],
            ...listecolor.flat()
          ])
          // recenter the map on all the districts
          const bbTool = boundingBox(props.district, map, getFontSize())
          bbTool.fitToAll()
        } else if (newVal?.sections) {
          visnone('district')
          visvisible('section', 'district-borders', 'symbols')
          map.setPaintProperty('district-borders', 'line-color', '#ffffff') // set district border to white
          map.setPaintProperty('district-borders', 'line-width', 1.5)
          const listecolorpartic = newVal.sections.map(element => {
            return [element.section_id, element.color]
          })
          listecolorpartic.push('white') // push a last color to get even number for mapbox style
          map.setPaintProperty('section', 'fill-color', [
            'match',
            ['get', 'section'],
            ...listecolorpartic.flat()
          ])
          // removing filter set for s9
          map.setFilter('section', null)
          map.setFilter('district-borders', null)
          // zoom to all the district to eliminate the zoom from the s9 slide
          const bbTool = boundingBox(props.district, map, getFontSize())
          bbTool.fitToAll()
        } else if (newVal?.candidatsdata) {
          visnone('district', 'symbols')
          visvisible(
            'district-borders',
            'section',
            'section-labels',
            'section-borders'
          )
          map.setPaintProperty('district-borders', 'line-width', 3)
          map.setPaintProperty('district-borders', 'line-color', '#000000')
          const listeavance = newVal.candidatsdata.map(element => {
            return [element.section_id, element.color]
          })
          // const listsectionid = newVal.candidatsdata.map(element => {
          //   return element.section_id
          // })
          // filter on the sections
          map.setFilter('section', [
            'in',
            ['get', 'section'],
            ['literal', newVal.sectionIds]
          ])
          map.setFilter('section-labels', [
            'in',
            ['get', 'section'],
            ['literal', newVal.sectionIds]
          ])
          map.setFilter('section-borders', [
            'in',
            ['get', 'section'],
            ['literal', newVal.sectionIds]
          ])
          const fillColorExpression = listeavance.length
            ? ['match', ['get', 'section'], ...listeavance.flat(), 'white']
            : 'white'
          map.setPaintProperty('section', 'fill-color', fillColorExpression)
          // filter on the district
          map.setFilter('district-borders', [
            '==',
            ['get', 'district'],
            newVal.district
          ])
          // recenter the map on the sections selected
          const filter = e => e.properties.district === newVal.district
          const bbTool = boundingBox(props.district, map, getFontSize())
          bbTool.fitToFilter(filter)
          // for s8
        } else if (newVal?.districtsfors8) {
          visnone('section')
          visvisible('district', 'district-borders', 'symbols')
          map.setPaintProperty('district-borders', 'line-color', '#ffffff')
          map.setPaintProperty('district-borders', 'line-width', 1)
          const listecolor = newVal.districtsfors8.map(element => {
            return [element.District, element.color]
          })
          listecolor.push('white') // push a last color to get even number for mapbox style
          map.setPaintProperty('district', 'fill-color', [
            'match',
            ['get', 'district'],
            ...listecolor.flat()
          ])
          const bbTool = boundingBox(props.district, map, getFontSize())
          bbTool.fitToAll()
        }
      }
    )
    return {
      mapContainer,
      elementStyle,
      options
    }
  }
}
</script>
