<template>
  <div>
    <gl-map ref="mapContainer" :init-map-options="options" />
  </div>
</template>

<script>
import GlMap from '@anagraph/gl-map'
import { shallowRef, onMounted, watch } from 'vue'
import quebecJson from '../config/villes-geojson.json'
import mapboxgl from 'mapbox-gl'
import * as turf from '@turf/turf'
import { generateLinks } from '../lib/elections-helper'
export default {
  components: {
    GlMap
  },
  props: {
    token: {
      type: String,
      default: null
    },
    selectedFeature: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const mapContainer = shallowRef(null)
    const defaultZoom = 6.2
    let initialBounds

    watch(
      () => props.selectedFeature,
      () => {
        const map = mapContainer.value.map

        if (props.selectedFeature) {
          const targetCity = quebecJson.features.find(
            feature =>
              feature.properties.Municipalite ===
              props.selectedFeature.properties.Municipalite
          )

          const geometry = targetCity.geometry
          const bbox = turf.bbox(geometry)

          map.fitBounds(bbox)
        } else {
          map.fitBounds(initialBounds)
        }
      }
    )

    onMounted(() => {
      const map = mapContainer.value.map

      map.setCenter([-72, 47.3])
      map.setZoom(defaultZoom)
      initialBounds = map.getBounds()

      map.on('load', () => {
        map.addSource('quebec', {
          type: 'geojson',
          data: quebecJson
        })

        map.addLayer({
          id: 'quebec-fills',
          type: 'fill',
          source: 'quebec',
          layout: {},
          paint: {
            'fill-color': '#627BC1',
            'fill-opacity': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              1,
              0.5
            ]
          }
        })

        map.addLayer({
          id: 'quebec-borders',
          type: 'line',
          source: 'quebec',
          layout: {},
          paint: {
            'line-color': '#627BC1',
            'line-width': 2
          }
        })

        emit('loaded', true)
      })

      map.on('click', 'quebec-fills', e => {
        const municipalite = e.features[0].properties.Municipalite
        // const uid = e.features[0].properties.uid
        const region = e.features[0].properties.Region
        const coordinates = e.features[0].geometry.coordinates
        const polygon = turf.polygon(coordinates)
        const center = turf.centerOfMass(polygon).geometry.coordinates

        const popupHTML = `
        <div class="city-popup">
          <h3>${municipalite}</h3>
          <div class="city-popup__description">
            <div>${region}</div>
          </div>
          <div style="display: flex; flex-direction: column">${generateLinks(
            municipalite
          )}</div>
        <div>`

        new mapboxgl.Popup()
          .setLngLat(center)
          .setHTML(popupHTML)
          .addTo(map)
      })

      map.addControl(new mapboxgl.NavigationControl(), 'bottom-right')

      map.on('mouseenter', 'quebec-fills', () => {
        map.getCanvas().style.cursor = 'pointer'
      })

      map.on('mouseleave', 'quebec-fills', () => {
        map.getCanvas().style.cursor = ''
      })
    })

    const transformRequest = (url, resourceType) => {
      if (!process.env.VUE_APP_PROTECTED_TILES_URL) return
      if (
        resourceType === 'Tile' &&
        url.startsWith(process.env.VUE_APP_PROTECTED_TILES_URL)
      ) {
        console.log(
          url,
          url.startsWith(process.env.VUE_APP_PROTECTED_TILES_URL),
          process.env.VUE_APP_PROTECTED_TILES_URL
        )
        return {
          url: url,
          headers: { Authorization: `Bearer ${props.token}` }
        }
      }
    }
    return {
      mapContainer,
      options: {
        center: [-71, 47.5],
        zoom: defaultZoom,
        transformRequest,
        style: 'https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json'
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
