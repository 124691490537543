<template>
  <div class="map-slide">
    <div class="map-slide__content">
      <slot name="content" />
    </div>
    <div class="map-slide__map">
      <slot name="map" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.map-slide {
  display: flex;
  width: 100%;
  height: 37.5rem;
  @media (max-height: 1024px) {
    height: 33.75rem;
  }
  @media (max-height: 899px) {
    height: 25.375rem;
  }
  &__content {
    width: (28.125rem - 1.75rem);
    font-size: 1.5rem;
    @media (max-height: 1024px) {
      font-size: 1.25rem;
    }
    @media (max-height: 899px) {
      font-size: 1rem;
    }
    @media (max-width: 1024px) {
      width: (18rem - 1rem);
    }
    background-color: #ffffff;
    border-radius: 0.625rem;
  }
  &__map {
    width: 58.125rem;
    border: 1px solid;
    @media (max-height: 1024px) {
      width: 52.31rem;
    }
    @media (max-height: 899px) {
      width: 39.33rem;
    }
    overflow: hidden;
    border-radius: 0.625rem;
  }
  justify-content: space-evenly;
}
</style>
