<template>
  <div class="list">
    <div
      v-for="(item, index) in mCandidates"
      :key="index"
      class="list__card"
      :class="{ 'empty-card': !item }"
    >
      <!-- {{ item.name }} -->
      <list-card v-if="item" :data="item" />
    </div>
    <div v-if="isIpad" class="list__card"></div>
  </div>
</template>

<script>
import ListCard from '@/components/listCard'
import { computed } from '@vue/runtime-core'
export default {
  components: {
    ListCard
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        candidats: [...Array(8).keys()].map(i => ({
          name: `Candidat Numéro ${i + 1}`
        }))
      })
    }
  },
  setup(props) {
    const mCandidates = computed(() => {
      if (props.data.candidats.length === 6) {
        return [
          ...props.data.candidats.slice(0, 3),
          null,
          null,
          ...props.data.candidats.slice(-3)
        ]
      }
      return props.data.candidats
    })
    return {
      mCandidates,
      isIpad: navigator.platform.match(/iPad/i)
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 37.5rem;
  width: 100%;
  border-radius: 0.625rem;
  // TODO: reactivate ?
  //   background-color: white;
  //   box-shadow: 0 38px 60px -50px rgba(0, 0, 0, 0.52);
  @media (max-height: 899px) {
    height: 25.375rem;
  }
  &__card {
    width: calc(50% - 0.0625rem);
    height: 7.35rem;
    @media (max-height: 899px) {
      height: 4.95rem;
    }
    border: solid 1px #f0f0f0;
    background-color: white;
    &.empty-card {
      background-color: transparent;
    }
  }
}
</style>
