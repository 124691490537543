import { displayNum, getCandidatById } from './utils'
import { getMairieTop3 } from './dataTransformersHelper'
export default (results, params, location) => () => {
  const baseUrl = process.env.VUE_APP_API_URL.includes('storage.googleapis.com')
    ? `${process.env.VUE_APP_API_URL}/${location}/current`
    : `${process.env.VUE_APP_API_URL}/${location}`
  const top3 = getMairieTop3(results)
  const top3Formatted = top3.map(item => {
    const candidat = getCandidatById(params, item.candidat_id)
    if (!candidat)
      throw new Error('can not find candidat for item ' + JSON.stringify(item))
    return {
      image: `${baseUrl}/${item.candidat_id}.jpg`,
      name: candidat.candidat_label,
      color: '#' + candidat.candidat_couleur,
      votes: displayNum(item.votes_quantite),
      taux: displayNum(item.votes_taux)
    }
  })
  const title = params.postes[0].poste_label
  const slideContent = { type: 's2', candidats: top3Formatted }
  return {
    type: 's2',
    description: 'S2: Résultats de la mairie — mode plein écran',
    template: 'CandidatSlide',
    data: { slideContent, title }
  }
}
