<template>
  <apexchart
    width="100%"
    height="100%"
    type="bar"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
import { ref } from 'vue'
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    category: {
      type: Array,
      default: () => null
    },
    categvalues: {
      type: Array,
      default: () => null
    },
    yaxisname: {
      type: String,
      default: null
    },
    barcolor: {
      type: String,
      default: null
    },
    valueFormatter: {
      type: Function,
      default: val => val
    },
    percent: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const categoryprops = ref(props.category)
    const categvaluesprops = ref(props.categvalues)
    const yaxisnameprops = ref(props.yaxisname)
    const barcolorprops = ref(props.barcolor)
    const formatterprops = ref(props.valueFormatter)
    const percent = ref(props.percent)
    return {
      options: {
        chart: {
          id: 'les-scrutins',
          toolbar: {
            show: false
          }
        },
        fill: {
          colors: barcolorprops.value
        },
        xaxis: {
          categories: categoryprops.value,
          labels: {
            style: {
              fontSize: '0.938rem',
              fontFamily: 'SF Pro Bold',
              colors: '#949494'
            },
            maxHeight: 400
          }
        },
        dataLabels: {
          enabled: false // remove label that display result on the bar chart
        },
        yaxis: {
          title: {
            text: yaxisnameprops.value,
            style: {
              fontSize: '0.938rem',
              fontFamily: 'SF Pro Bold',
              color: '#949494'
            }
          },
          forceNiceScale: false,
          tickAmount: 4,
          labels: {
            // add percentage to y axis
            formatter: formatterprops.value,
            style: {
              fontSize: '0.938rem',
              fontFamily: 'SF Pro Bold',
              colors: '#949494'
            }
          },
          max: percent.value ? 100 : undefined
        }
      },
      series: [
        {
          data: categvaluesprops.value
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
