import * as turf from '@turf/turf'

export function boundingBox(geojsonfile, map, fontSize) {
  function fitToFilter(filter) {
    const newGeojson = {
      ...geojsonfile,
      features: geojsonfile.features.filter(filter)
    }
    map.fitBounds(turf.bbox(newGeojson), { padding: fontSize })
  }
  function fitToAll() {
    map.fitBounds(turf.bbox(geojsonfile), { padding: fontSize })
  }

  return {
    fitToAll,
    fitToFilter
  }
}
