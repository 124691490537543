<template>
  <div v-if="ready" id="app" ref="root">
    <router-view :key="resizeKey" class="full-screen-wrapper" />
    <div
      class="full-screen-button"
      :title="isFullscreen ? 'désactiver plein écran' : 'activer plein écran'"
      @click="toggleFullscreen"
    >
      <i
        class="fa fa-2x"
        :class="isFullscreen ? 'fa-compress' : 'fa-expand'"
      ></i>
    </div>
  </div>
</template>
<script>
import { api as fullscreen } from 'vue-fullscreen'
import { computed, ref } from 'vue'
export default {
  setup() {
    const ready = computed(() => {
      return (
        !process.env.VUE_APP_AUTH_ENABLED ||
        !this.$store.state.auth.connected ||
        this.$store.state.auth.token
      )
    })
    const root = ref()
    const resizeKey = ref(0)
    const isFullscreen = ref(false)
    function toggleFullscreen() {
      fullscreen.toggle(root.value.querySelector('.fullscreen-wrapper'), {
        // teleport: false,
        callback: isFullscreenStatus => {
          resizeKey.value++
          isFullscreen.value = isFullscreenStatus
        }
      })
    }
    window.addEventListener('resize', () => {
      resizeKey.value++
    })
    return {
      ready,
      isFullscreen,
      toggleFullscreen,
      root,
      resizeKey
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'SF Pro Black';
  src: url('./assets/fonts/SF-Pro-Display-Black.otf');
}
@font-face {
  font-family: 'SF Pro Medium';
  src: url('./assets/fonts/SF-Pro-Display-Medium.otf');
}
@font-face {
  font-family: 'SF Pro SemiBold';
  src: url('./assets/fonts/SF-Pro-Display-Semibold.otf');
}
@font-face {
  font-family: 'SF Pro Bold';
  src: url('./assets/fonts/SF-Pro-Display-Bold.otf');
}
html {
  @media (min-height: 1024px) {
    font-size: 1vw;
    color: #2d2d2d;
  }
}

#app {
  height: 100vh;
  font-family: 'SF Pro Black', sans-serif; // TODO load font
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.full-screen-button {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.panel {
  font-family: 'SF Pro Medium', sans-serif;
  overflow: visible !important;
  min-height: unset !important;
  padding: 0 1.5rem 2rem;

  h3 {
    font-family: 'SF Pro Bold', sans-serif;
    text-align: left;
  }
}

.mapboxgl-popup {
  max-width: unset !important;
}

.city-popup {
  font-family: 'SF Pro Medium';
  font-size: 1rem;
  margin: 1rem;
  &__description {
    padding-bottom: 2rem;
    text-align: left;

    & div:nth-child(1) {
      margin-bottom: 0.4rem;
    }
  }
  a {
    font-family: 'SF Pro SemiBold', sans-serif;
    color: #2c3e509d;
    padding: 0.6125rem 0.75rem;
    background-color: #eee;
    border-radius: 0.125rem;
    text-decoration: none;
    &:hover {
      color: #2c3e50;
      background-color: #ddd;
    }
  }
}
</style>
