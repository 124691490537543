import { DateTime } from 'luxon'
import {
  s2,
  s3,
  s4,
  s5,
  s6,
  s7,
  s8,
  s9,
  s10,
  s11,
  s12,
  s13,
  s14
} from './dataTransformers'
import { displayNum, getCandidatById } from './dataTransformers/utils'
const geoSlideTypes = ['s6', 's8', 's9', 's10']
const participationSlideTypes = ['s10', 's11', 's12', 's13', 's14']
const resultsSlideTypes = ['s2', 's3', 's4', 's5', 's6', 's7', 's8', 's9']
const mairieTypes = ['s2', 's5', 's6', 's7', 's8']
export function getSlides(
  params,
  results,
  participation,
  config,
  section,
  location,
  slideTypeFilter,
  slideIndexFilter,
  flat = true
) {
  const dataTransformers = {
    s2: s2(results, params, location),
    s3: s3(results, params, location),
    s4: s4(results, params),
    s5: s5(results, params),
    s6: s6(results, params),
    s7: s7(results, params),
    s8: s8(results, params),
    s9: s9(results, params),
    s10: s10(section, participation),
    s11: s11(participation),
    s12: s12(participation),
    s13: s13(participation),
    s14: s14(participation)
  }
  const slideTypes = Object.keys(dataTransformers)
  const availableSlideTypes = slideTypes.filter(slideType => {
    if (slideTypeFilter && slideTypeFilter !== slideType) {
      return false
    }
    if (!params.Laisser_Affiche_Maire && mairieTypes.includes(slideType)) {
      return false
    }
    if (!config.hasGeo && geoSlideTypes.includes(slideType)) {
      return false
    }
    if (
      (!participation || !config.hasParticipation) &&
      participationSlideTypes.includes(slideType)
    ) {
      return false
    }
    if (
      (!results || !config.hasResults) &&
      resultsSlideTypes.includes(slideType)
    ) {
      return false
    }
    if (
      config.slides.default.visible === true &&
      config.slides[slideType]?.visible === false
    ) {
      return false
    }
    if (
      config.slides.default.visible === false &&
      !config.slides[slideType]?.visible === true
    ) {
      return false
    }
    return true
  })
  const slideByTypes = availableSlideTypes
    .map(slideType => {
      try {
        return dataTransformers[slideType]()
      } catch (error) {
        console.error('can not generate slide for type ' + slideType)
        console.error(error)
        return null
      }
    })
    .filter(Boolean)
  if (!flat) return slideByTypes
  const slides = slideByTypes.flat()
  if (slideIndexFilter) {
    return [slides[slideIndexFilter - 1]]
  }
  return slides
}

function getDisplayDate(date) {
  const dt = DateTime.fromISO(date)
  const formattedDate = dt.toFormat('yyyy-MM-dd')
  const formattedHour = dt.toFormat('H')
  const formattedMin = dt.toFormat('mm')
  return `${formattedDate} @ ${formattedHour}h${formattedMin}`
}

export function getSlideContainerData(results, params, participation) {
  const posteMairie = results.resultats_postes.find(
    poste => poste.poste_id === 1
  )
  const scrutin = posteMairie.boite_de_scrutin
  const candidats = posteMairie.resultats_candidats
  const bannerItems = candidats.map(item => {
    const candidat = getCandidatById(params, item.candidat_id)
    return {
      name: candidat.candidat_label,
      color: '#' + candidat.candidat_couleur,
      taux: displayNum(item.votes_taux)
    }
  })
  const updatedAt = results
    ? results.updated_at
    : participation
    ? participation.updated_at
    : null
  const majInfos = getDisplayDate(updatedAt)
  const election = params.election_label
  document.title = `${params.ville_label} : ${election}`
  return {
    bannerPoste: params?.postes[0]?.poste_label,
    bannerItems,
    scrutin,
    majInfos,
    election
  }
}
