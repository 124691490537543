<template>
  <apexchart
    width="100%"
    height="100%"
    type="bar"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
import { ref } from 'vue'
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    category: {
      type: Array,
      default: () => null
    },
    categvaluesone: {
      type: Array,
      default: () => null
    },
    categvaluestwo: {
      type: Array,
      default: () => null
    },
    yaxisname: {
      type: String,
      default: null
    },
    colorone: {
      type: String,
      default: null
    },
    colortwo: {
      type: String,
      default: null
    },
    valueFormatter: {
      type: Function,
      default: val => val
    },
    percent: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const categoryprops = ref(props.category)
    const categvaluesoneprops = ref(props.categvaluesone)
    const categvaluestwoprops = ref(props.categvaluestwo)
    const yaxisnameprops = ref(props.yaxisname)
    const coloroneprops = ref(props.colorone)
    const colortwoprops = ref(props.colortwo)
    const formatterprops = ref(props.valueFormatter)
    const percent = ref(props.percent)
    return {
      options: {
        chart: {
          id: 'les-scrutins',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '75%',
            borderRadius: 2
          }
        },
        stroke: {
          // add space between bar chart
          width: 1,
          colors: ['#fff']
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: categoryprops.value,
          labels: {
            style: {
              fontSize: '0.938rem',
              fontFamily: 'SF Pro Bold',
              colors: '#949494'
            }
          }
        },
        dataLabels: {
          enabled: false // remove label that display result on the bar chart
        },
        colors: [coloroneprops.value, colortwoprops.value],
        yaxis: {
          title: {
            text: yaxisnameprops.value,
            style: {
              fontSize: '0.938rem',
              fontFamily: 'SF Pro Bold',
              color: '#949494'
            }
          },
          forceNiceScale: true,
          labels: {
            // add percentage to y axis
            formatter: formatterprops.value,
            style: {
              fontSize: '0.938rem',
              fontFamily: 'SF Pro Bold',
              colors: '#949494'
            }
          },
          max: percent.value ? 100 : undefined
        }
      },
      series: [
        {
          data: categvaluesoneprops.value
        },
        {
          data: categvaluestwoprops.value
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
