<template>
  <div class="candidats-slide">
    <div class="candidats-slide__rectangle">
      <div>
        Pourcentage des votes dépouillés et valides, par candidat
      </div>
    </div>
    <div class="candidats-slide__graph">
      <div class="candidats-slide__graph__chartstyle">
        <css-chart :items="candidats" />
      </div>
    </div>
  </div>
</template>

<script>
import CssChart from '@/components/CssBasicBarChart.vue'
export default {
  components: {
    CssChart
  },
  props: {
    data: {
      type: Object,
      default: () => null
    }
  },
  setup(props) {
    return {
      candidats: props.data.candidats
    }
  }
}
</script>

<style lang="scss" scoped>
.candidats-slide {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  // height: 100%;
  &__rectangle {
    display: flex;
    align-items: center;
    font-family: 'SF Pro SemiBold';
    font-size: 1.5rem;
    width: 87.5rem;
    height: 3rem;
    margin-top: 35px;
    padding: 1.25rem 21.75rem;
    border-radius: 4px;
    box-shadow: 0 15px 60px -35px rgba(0, 0, 0, 0.52);
    background-color: white;
  }
  &__graph {
    margin-top: 25px;
    width: 82.938rem;
    height: 28.625rem;
    &__chartstyle {
      height: 95%;
    }
  }
}
</style>
