function getDistrictLabel(params, districtId) {
  const district = params.districts.find(
    d => parseInt(d.district_id) === parseInt(districtId)
  )
  if (!district) {
    throw new Error('can not find label for district id ' + districtId)
  }
  return district.district_label
}

export default (results, params) => () => {
  const districts = results.depouillement_districts.map(item => {
    const district = getDistrictLabel(params, item.district_id)
    return {
      district,
      pourcentage: item.depouillement_taux
    }
  })
  const slideContent = {
    type: 's4',
    yaxisname: 'AVANCEMENT DU DÉPOUILLEMENT',
    color: '#1f74c1',
    valueFormatter: val => (val === 0 ? val : val + '%'),
    percent: true,
    districts
  }
  const title = 'L’ÉLECTION EN CHIFFRES'
  return {
    type: 's4',
    description: 'S4: Boîte de scrutin dépouillées par district',
    template: 'ElectionSlide',
    data: { slideContent, title }
  }
}
