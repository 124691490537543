<template>
  <div class="list-card">
    <div class="list-card__candidat">
      <div
        class="list-card__candidat__image"
        :style="{
          backgroundImage: `url(${data.image})`,
          borderColor: data.color
        }"
      ></div>
      <div class="list-card__candidat__infos">
        <div class="list-card__candidat__infos__name">{{ data.name }}</div>
        <div class="list-card__candidat__infos__parti">{{ data.parti }}</div>
      </div>
    </div>
    <div class="list-card__result" :class="{ avance: data.avance }">
      <div class="list-card__result__taux">{{ data.taux }} %</div>
      <div class="list-card__result__votes">{{ data.votes }} votes</div>
      <div v-if="data.avance" class="list-card__result__avance">
        avance de {{ data.avance }} votes
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        image: 'http://localhost:8080/json-test/010102.jpg',
        name: 'Pascal Bonin',
        parti: 'Projet Granby',
        taux: '61.97%',
        votes: '12 437 votes'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list-card {
  display: flex;
  justify-content: space-between;
  padding: 0 1.875rem;
  font-family: 'SF Pro Bold';
  font-size: 1.75rem;
  @media (max-height: 899px) {
    font-size: 1.25rem;
  }
  &__candidat {
    display: flex;
    padding-top: 2rem;
    max-width: 37.5rem;
    @media (max-height: 899px) {
      padding-top: 1rem;
      max-width: 20rem;
    }
    &__image {
      width: 3.75rem;
      height: 3.75rem;
      border: 0.2rem solid;
      border-radius: 1.25rem;
      @media (max-height: 899px) {
        width: 2.563rem;
        height: 2.563rem;
        border-radius: 0.84375rem;
      }
      margin-right: 1.05rem;
      background-repeat: no-repeat;
      background-position: center;
      background-color: grey;
      background-size: 100%;
    }
    &__infos {
      text-align: left;
      &__parti {
        font-family: 'SF Pro Medium';
        font-size: 1.125rem;
        @media (max-height: 899px) {
          font-size: 0.6175rem;
        }
        color: #919191;
      }
    }
  }
  &__result {
    text-align: right;
    padding-top: 2rem;
    @media (max-height: 899px) {
      padding-top: 1rem;
    }
    &.avance {
      padding-top: 1rem;
      @media (max-height: 899px) {
        padding-top: 0.5rem;
      }
    }
    &__votes {
      font-family: 'SF Pro Medium';
      font-size: 1.5rem;
      @media (max-height: 899px) {
        font-size: 0.875rem;
      }
      color: #919191;
    }
    &__avance {
      font-family: 'SF Pro Bold';
      font-size: 1.5rem;
      @media (max-height: 899px) {
        font-size: 0.875rem;
      }
    }
  }
}
</style>
