module.exports = {
  default: {
    hasParticipation: false,
    hasGeo: false
  },
  cities: {
    Granby: {
      hasParticipation: true,
      hasGeo: true
    },
    Levis: {
      hasParticipation: false,
      hasGeo: true
    }
  }
}
