import { participationSectionsChoropleth } from '@/lib/statsHelper'
const choroMethod = 'breakpoints'
export default (section, participation) => () => {
  const slideContent = {
    type: 's10'
  }
  const participationColors = ['#edf3d1', '#95cb82', '#309951', '#076a38']
  const breakpoints = [20, 40, 60]
  // const sectionIds = section.features.map(f => f.properties.section)
  const participationSections = participation.participation_sections.filter(
    item =>
      section.features.find(
        s =>
          s.properties.section === item.section_id &&
          s.properties.district === item.district_id
      )
  )
  const sections = participationSectionsChoropleth(
    participationSections,
    participationColors,
    '#cfcfcf',
    choroMethod,
    breakpoints
  )
  const mapContent = {
    sections
  }
  const title = 'L’ÉLECTION EN CHIFFRES'
  return {
    type: 's10',
    description:
      'S10: Taux de participation des électeurs par section de votes (vue ville)',
    template: 'MapSlide',
    data: { slideContent, title, mapContent }
  }
}
