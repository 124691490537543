import { displayNum, getCandidatById } from './utils'
export default (results, params, location) => {
  const baseUrl = process.env.VUE_APP_API_URL.includes('storage.googleapis.com')
    ? `${process.env.VUE_APP_API_URL}/${location}/current`
    : `${process.env.VUE_APP_API_URL}/${location}`
  function getSlide(posteId) {
    const poste = results.resultats_postes.find(
      poste => poste.poste_id === posteId
    )
    const resultatsCandidats = [...poste.resultats_candidats]
    const candidats = resultatsCandidats.map(rc => {
      const candidat = getCandidatById(params, rc.candidat_id)
      if (!candidat) {
        throw new Error('can not find candidat for item ' + JSON.stringify(rc))
      }
      const {
        candidat_label: name,
        parti_label: parti,
        candidat_id: id,
        candidat_couleur: color
      } = candidat
      const { votes_quantite: votes, votes_taux: taux, avance } = rc
      return {
        image: `${baseUrl}/${id}.jpg`,
        name,
        parti: parti || '',
        taux: displayNum(taux),
        votes: displayNum(votes),
        avance: avance || null,
        color: `#${color}`
      }
    })
    const data = {
      slideContent: { type: 's3', candidats },
      scrutin: poste.boite_de_scrutin,
      title: poste.poste_label
    }
    return {
      type: 's3',
      description:
        'S3: Dépouillement/avance de la mairie et d’un district — mode comparatif',
      template: 'ListSlide',
      data
    }
  }
  return () => results.resultats_postes.map(({ poste_id: id }) => getSlide(id))
}
