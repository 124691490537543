function correctProperties(feature) {
  const correctedProperties = Object.fromEntries(
    Object.entries(feature.properties).map(([key, value]) => {
      const correctedKey = key.toLowerCase()
      const correctedValue = ['district', 'section'].includes(correctedKey)
        ? parseInt(value)
        : value
      return [correctedKey, correctedValue]
    })
  )
  return { ...feature, properties: correctedProperties }
}
function addDistrictLabel(feature, districtsParams) {
  const districtParam = districtsParams.find(
    d => parseInt(d.district_id) === parseInt(feature.properties.district)
  )
  if (!districtParam?.district_label) {
    throw new Error('can not find label for district id ' + feature.district)
  }
  return {
    ...feature,
    properties: {
      ...feature.properties,
      district_label: districtParam.district_label
    }
  }
}
export function normalizeGeoJSON(geoJSON) {
  const correctedFeatures = geoJSON.features.map(correctProperties)

  return { ...geoJSON, features: correctedFeatures }
}
export function addDistrictLabels(geoJSON, districtsParams) {
  const features = geoJSON.features.map(feature =>
    addDistrictLabel(feature, districtsParams)
  )
  return { ...geoJSON, features }
}

export function addSectionsDistricts(sections, params) {
  const features = sections.features.map(s => {
    const district = params.value.sections.find(
      ps => ps.section_id === parseInt(s.properties.Section)
    )
    const District = parseInt(s.properties.District)
    return {
      ...s,
      properties: {
        ...s.properties,
        District: District ?? district.district_id
      }
    }
  })
  return {
    ...sections,
    features
  }
}
