<template>
  <div class="candidats-slide">
    <div class="candidats-slide__rectangle">
      <div class="candidats-slide__rectangle__titre">
        <h2>Boîtes de scrutins dépouillées, par district</h2>
      </div>
      <div class="candidats-slide__rectangle__rec2">
        <div
          class="candidats-slide__rectangle__rec2__carre"
          :style="{ backgroundColor: data.color }"
        ></div>
        <h2>Pourcentage</h2>
      </div>
    </div>
    <div class="candidats-slide__graph">
      <div class="candidats-slide__graph__chartstyle">
        <ApexChart
          :category="category"
          :categvalues="categvalues"
          :yaxisname="data.yaxisname"
          :barcolor="data.color"
          :value-formatter="data.valueFormatter"
          :percent="data.percent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApexChart from '../apexChart.vue'
export default {
  components: {
    ApexChart
  },
  props: {
    data: {
      type: Object,
      default: () => null
    }
  },
  setup(props) {
    const category = props.data.districts.map(d => {
      return d.district
    })
    const categvalues = props.data.districts.map(d => {
      return d.pourcentage
    })
    return {
      category,
      categvalues
    }
  }
}
</script>

<style lang="scss" scoped>
.candidats-slide {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // height: 100%;
  &__rectangle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SF Pro Bold';
    font-variation-settings: 'opsz' 24;
    font-size: 0.825rem;
    width: 87.5rem;
    height: 3rem;
    margin-top: 2.188rem;
    padding: 1.25rem 12.75rem;
    border-radius: 0.25rem;
    box-shadow: 0rem 0.938rem 3.75rem -2.188rem rgba(0, 0, 0, 0.52);
    background-color: white;
    &__titre {
      padding-right: 2rem;
      white-space: nowrap;
    }
    &__rec2 {
      display: flex;
      align-items: center;
      &__carre {
        height: 1.25rem;
        width: 1.25rem;
        margin-right: 0.313rem;
        border-radius: 0.313rem;
      }
    }
  }
  &__graph {
    margin-top: 1.563rem;
    width: 82.938rem;
    height: 28.625rem;
    &__chartstyle {
      height: 28rem;
    }
  }
}
</style>
