<template>
  <div class="home" @mousemove="activatePlayer" @click="activatePlayer">
    <config-panel
      v-if="slides"
      v-show="playerVisible"
      v-model:paused="paused"
      :index="currentSlideIndex"
      :type="env === 'development' ? type : null"
      :duration="duration"
      @skip="skip"
    />
    <slide-container
      v-if="slides && slides.length"
      :scrutin="
        slides[currentSlideIndex].data.scrutin || SlideContainerData?.scrutin
      "
      :title="slides[currentSlideIndex].data.title"
      :location-label="locationLabel"
      :maj-infos="SlideContainerData?.majInfos"
      :election="SlideContainerData?.election"
      :scroll="slides[currentSlideIndex].type !== 's2'"
    >
      <template #body>
        <map-slide
          v-if="config.hasGeo"
          v-show="!loaded || slides[currentSlideIndex].template === 'MapSlide'"
          class="content-wrapper"
        >
          <template #map>
            <Map
              v-if="config.hasGeo && basemap"
              :selected="
                loaded ? slides[currentSlideIndex].data.district_id : false
              "
              :data="loaded ? slides[currentSlideIndex].data.mapContent : null"
              :district="district ? district : null"
              :section="section ? section : null"
              :basemap="basemap"
              @loaded="loaded = true"
            />
          </template>
        </map-slide>
        <component
          :is="slides[currentSlideIndex].template"
          :data="slides[currentSlideIndex].data.slideContent"
          :type="slides[currentSlideIndex].data.slideContent.type"
          class="content-wrapper"
          style="pointer-events: none"
        >
        </component>
      </template>
      <template #banner>
        <banner
          v-if="hasBanner && SlideContainerData"
          :items="SlideContainerData?.bannerItems"
          :poste="SlideContainerData?.bannerPoste"
        />
      </template>
    </slide-container>
    <div v-show="!loaded" class="loader">
      <div class="loader-content">chargement en cours ...</div>
    </div>
    <div v-show="slides && !slides.length" class="loader">
      <div class="loader-content">élection non débutée ...</div>
    </div>
    <div v-show="preprod" class="loader">
      <div class="loader-content">
        À venir: {{ location ? location + ', ' : '' }}
        tableau de bord des élections municipales
      </div>
    </div>
    <div v-show="notFound" class="loader">
      <div class="loader-content">{{ city }} : ville non trouvée</div>
    </div>
  </div>
</template>

<script>
import locations from '../locations'
import slugify from 'slugify'
import { useRoute } from 'vue-router'
import Map from '@/components/map'
import SlideContainer from '@/components/templates/slideContainer'
import MapSlide from '@/components/slides/mapSlide'
import ChartSlide from '@/components/slides/chartSlide'
import s5Votes from '@/components/slides/s5Votes'
import CandidatSlide from '@/components/slides/candidatSlide'
import s7Votes from '@/components/slides/s7Votes'
import ElectionSlide from '@/components/slides/electionSlide'
import s11Votes from '@/components/slides/s11Votes'
import ElectionSlide12 from '@/components/slides/electionSlide12'
import ElectionSlide13 from '@/components/slides/electionSlide13'
import ElectionSlide14 from '@/components/slides/electionSlide14'
import ListSlide from '@/components/slides/listSlide.vue'
import { computed, onMounted, watch } from '@vue/runtime-core'
import { ref } from '@vue/reactivity'
import { getDataFile } from '@/lib/apiClient'
import { getSlides, getSlideContainerData } from '@/lib/dataTransformer'
import Banner from '@/components/banner.vue'
import ConfigPanel from '@/components/ConfigPanel.vue'
import cityConfig from '../../functions/cityConfig'
import {
  normalizeGeoJSON,
  addDistrictLabels,
  addSectionsDistricts
} from '@/lib/geoJSON-helper'
export default {
  name: 'Home',
  components: {
    SlideContainer,
    Map,
    MapSlide,
    ChartSlide,
    s5Votes,
    CandidatSlide,
    s7Votes,
    ElectionSlide,
    s11Votes,
    ElectionSlide12,
    ElectionSlide13,
    ElectionSlide14,
    Banner,
    ListSlide,
    ConfigPanel
  },
  setup() {
    const timer = ref(null)
    const playerVisible = ref(false)
    const paused = ref(false)
    const route = useRoute()
    const city = slugify(route.params.city, { lower: true })
    const election = route.params.election
    const slideType = route.params.slideType
    const slideIndex = route.params.slideIndex
    const loaded = ref(false)
    const params = ref(null)
    const participation = ref(null)
    const config = ref(null)
    const results = ref(null)
    const district = ref(null)
    const section = ref(null)
    const dataReady = ref(false)
    const basemap = ref(null)
    const locationLabel = ref(null)
    const location = locations.find(l => slugify(l, { lower: true }) === city)
    const locationConfig = cityConfig.cities[location] || cityConfig.default
    console.log({ locationConfig })
    if (!location) {
      return { city, notFound: true, preprod: false }
    }

    if (process.env.VUE_APP_DISABLED_APP === 'true') {
      return { location, notFound: false, preprod: true }
    }

    const slides = computed(() =>
      dataReady.value === true
        ? getSlides(
            params.value,
            results.value,
            participation.value,
            config.value,
            section.value,
            location,
            slideType,
            slideIndex
          )
        : null
    )
    const SlideContainerData = computed(() =>
      results.value
        ? getSlideContainerData(
            results.value,
            params.value,
            participation.value
          )
        : null
    )
    const counter = ref(0)
    const currentSlideIndex = computed(
      () => counter.value % slides.value.length
    )
    const type = computed(() =>
      slides.value ? slides.value[currentSlideIndex.value]?.type : null
    )
    const duration = computed(() => {
      return type.value
        ? config.value.slides[type?.value]?.displayTime ||
            config.value.slides.default.displayTime
        : null
    })
    const hasBanner = computed(() => {
      return params.value?.Laisser_Affiche_Maire
    })
    onMounted(async () => {
      params.value = await getDataFile('parametres', location, election)
      const configValue = {
        hasGeo: locationConfig.hasGeo,
        hasParticipation: locationConfig.hasParticipation,
        hasResults: true,
        slides: {
          default: {
            displayTime: params.value.SecondesAffichage * 1000,
            visible: true
          }
        }
      }
      locationLabel.value = params.value.ville_label
      config.value = configValue
      results.value = await getDataFile('resultats', location, election)
      if (locationConfig.hasParticipation) {
        participation.value = await getDataFile(
          'participation',
          location,
          election
        )
      }

      if (config.value.hasGeo) {
        const districts = normalizeGeoJSON(
          require(`../geojson/${location}/district.json`)
        )
        const sections = normalizeGeoJSON(
          require(`../geojson/${location}/section.json`)
        )
        const districtsWithLabel = addDistrictLabels(
          districts,
          params.value.districts
        )
        const sectionsWithDistricts = addSectionsDistricts(sections, params)

        district.value = districtsWithLabel
        section.value = sectionsWithDistricts
        basemap.value = config.value.basemap || 'satellite'
      } else {
        loaded.value = true
      }

      dataReady.value = true
    })
    setInterval(async () => {
      const newResults = await getDataFile('resultats', location, election)
      // we update only if results are not null => slideshow stay alive with previous data if connection failure to the backend
      if (newResults) {
        // if there was no results before (first appearance or back after failure), reset slideshow
        if (results.value === null) {
          counter.value = 0
        }
        results.value = newResults
      }
    }, process.env.VUE_APP_RESULTS_REFRESH_INTERVAL)
    if (locationConfig.hasParticipation) {
      setInterval(async () => {
        const newParticipation = await getDataFile(
          'participation',
          location,
          election
        )
        if (newParticipation) {
          participation.value = newParticipation
        }
      }, process.env.VUE_APP_RESULTS_REFRESH_INTERVAL)
    }
    watch(loaded, () => {
      function nextSlide() {
        const slideType =
          slides.value[currentSlideIndex.value].data.slideContent.type
        const slideDisplayTime =
          config.value.slides[slideType]?.displayTime ||
          config.value.slides.default.displayTime
        setTimeout(() => {
          if (!paused.value) counter.value++
          nextSlide()
        }, slideDisplayTime)
      }
      nextSlide()
    })

    const activatePlayer = () => {
      playerVisible.value = true
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        playerVisible.value = false
      }, 3000)
    }
    return {
      slides,
      currentSlideIndex,
      loaded,
      SlideContainerData,
      district,
      section,
      basemap,
      config,
      paused,
      skip: value => {
        const newCounterValue = counter.value + value
        if (newCounterValue < 0) return
        counter.value = newCounterValue
      },
      type,
      duration,
      env: process.env.NODE_ENV,
      preprod: false,
      notFound: false,
      city,
      location,
      hasBanner,
      activatePlayer,
      playerVisible,
      locationLabel
    }
  }
}
</script>
<style lang="scss">
.home {
  width: 100vw;
  height: 100vh;
}
.content-wrapper {
  position: absolute;
}
.loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  background: white;
  display: flex;
  &-content {
    font-family: 'SF Pro Medium';
    margin: auto;
    font-size: 2rem;
  }
}
</style>
