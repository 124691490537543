import _ from 'lodash'
export function displayNum(val, lang = 'fr') {
  return new Intl.NumberFormat(`${lang}-CA`, {
    maximumFractionDigits: 2
  }).format(val)
}

export function getDistrictLabel(params, districtId) {
  const district = params.districts.find(
    d => parseInt(d.district_id) === parseInt(districtId)
  )
  if (!district) {
    throw new Error('can not find label for district id ' + districtId)
  }
  return district.district_label
}

export function getMairieTop3(results) {
  const posteMairie = results.resultats_postes.find(poste =>
    poste.poste_label.includes('Maire')
  )
  const candidats = posteMairie.resultats_candidats
  const candidatsParNbVotes = _.orderBy(candidats, ['votes_quantite'], ['desc'])
  const top3 = candidatsParNbVotes.slice(0, 3)
  return top3
}

export function getTotalVotes(candidatsResults, rejetes) {
  const totalCandidatsVotes = candidatsResults.reduce(
    (acc, current) => acc + current.votes_quantite,
    0
  )
  const totalVotes = totalCandidatsVotes + rejetes
  return totalVotes
}
export function getCandidatColor(params, candidatId) {
  const candidat = params.candidats.find(c => c.candidat_id === candidatId)
  if (!candidat) {
    throw new Error('can not find candidat for item id=' + candidatId)
  }
  return '#' + candidat.candidat_couleur
}
export function round4Decimal(num) {
  return Math.round(num * 10000) / 10000
}
// TODO handle "autres candidats" quand le nombre de candidats est supérieur à 3
