<template>
  <div class="banner-element">
    <div
      v-if="color !== '#000000'"
      class="banner-element__color"
      :style="{ 'background-color': color }"
    ></div>
    <div class="banner-element__text">
      {{ name }} : <span>{{ taux }}</span>
    </div>
    <div class="banner-element__separator"></div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'blue'
    },
    name: {
      type: String,
      default: 'Pascal Bonin'
    },
    taux: {
      type: String,
      default: '65,35%'
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-element {
  display: flex;
  align-items: center;
  font-family: 'SF Pro Medium';
  &__color {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1.313rem;
    border-radius: 0.5625rem;
    // background-color: #2c9c8d;
  }
  &__text {
    font-size: 1.5rem;
    margin-right: 2.5rem;
    span {
      font-family: 'SF Pro Bold';
    }
  }
  &__separator {
    width: 0.125rem;
    height: 2.5rem;
    margin-right: 2.5rem;
    background-color: #c2c2c2;
  }
}
</style>
