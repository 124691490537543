<template>
  <div class="home">
    <left-panel-layout :is-left-panel-open="mapLoaded" class="app-layout">
      <template #left-panel>
        <div v-if="mapLoaded">
          <div style="padding:1rem">
            <h3 style="text-align:left">Élections municipales 2021</h3>
            <v-select
              v-model="selectedOption"
              :options="options"
              :reduce="optionFeature => optionFeature"
              label="optionLabel"
              placeholder="Choisir une ville"
            ></v-select>
          </div>
        </div>
        <div v-else>loading ...</div>
      </template>
      <template #map>
        <Map
          :selected-feature="
            selectedOption === null ? null : selectedOption.optionFeature
          "
          @loaded="mapLoaded = true"
        />
      </template>
    </left-panel-layout>
  </div>
</template>

<script>
import LeftPanelLayout from '@anagraph/left-panel-layout'
import Map from '@/views/GeneralMap.vue'
import { getToken } from '@/commons/auth/firebase-auth'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import quebecJson from '@/config/villes-geojson.json'

export default {
  name: 'Home',
  components: { LeftPanelLayout, Map, vSelect },
  data() {
    return {
      mapLoaded: false,
      token: null,
      features: quebecJson.features,
      selectedOption: [],
      options: []
    }
  },
  async mounted() {
    this.options = quebecJson.features
      .map(feature => {
        return {
          optionLabel: feature.properties.Municipalite,
          optionFeature: feature
        }
      })
      .sort((a, b) => {
        const nameA = a.optionLabel.toUpperCase()
        const nameB = b.optionLabel.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    this.token = await getToken()
  }
}
</script>
<style lang="scss" scoped></style>
