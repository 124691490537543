module.exports = [
  'Alma',
  'Ange-Gardien',
  'Baie-Comeau',
  'Beaconsfield',
  'Beloeil',
  'Blainville',
  'Boisbriand',
  'Bromont',
  'Brossard',
  'Candiac',
  'Chambly',
  'Chateauguay',
  'Contrecoeur',
  'Cowansville',
  'Delson',
  'Dolbeau-Mistassini',
  'Dollard-des-Ormeaux',
  'Dorval',
  'Drummondville',
  'Granby',
  'Hampstead',
  'LaPrairie',
  'Levis',
  'Longueuil',
  'MRC-Montcalm',
  'Magog',
  'Mascouche',
  'Mirabel',
  'Mont-Royal',
  'Mont-Saint-Hilaire',
  'Montmagny',
  'Pointe-Calumet',
  'Pointe-Claire',
  'Repentigny',
  'Richelieu',
  'Rimouski',
  'Riviere-du-Loup',
  'Roberval',
  'Rosemere',
  'Saguenay',
  'Saint-Augustin-de-Desmaures',
  'Saint-Bruno-de-Montarville',
  'Saint-Constant',
  'Saint-Felicien',
  'Saint-Georges',
  'Saint-Hyacinthe',
  'Saint-Jean-sur-Richelieu',
  'Saint-Jerome',
  'Saint-Joseph-de-Sorel',
  'Saint-Lambert',
  'Saint-Lin-Laurentides',
  'Saint-Sauveur',
  'Sainte-Agathe-des-Monts',
  'Sainte-Catherine',
  'Sainte-Julie',
  'Sainte-Marie',
  'Sainte-Marthe-sur-le-Lac',
  'Sainte-Therese',
  'Salaberry-de-Valleyfield',
  'Shawinigan',
  'Sherbrooke',
  'Sorel-Tracy',
  'Stoneham-et-Tewkesbury',
  'Terrebonne',
  'Vaudreuil-Dorion',
  'Victoriaville'
]
