<template>
  <div class="not-found">
    <div class="not-found__container">
      <div>
        La page n'existe pas
      </div>
      <a href="/">retour à la page d'accueil</a>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.not-found {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  background: white;
  display: flex;
  &__container {
    font-family: 'SF Pro Medium';
    margin: auto;
    font-size: 1.5rem;
  }
}
</style>
