import villes2021 from './villes-2021.json'
import villes2022 from './villes-2022.json'
import villes2023 from './villes-2023.json'
import current from './villes-current.json'

export default [
  { id: 'current', route: '', label: 'en cours', municipalities: current },
  {
    id: '2023',
    route: '/2023',
    label: 'partielle 2023',
    municipalities: villes2023
  },
  {
    id: '2022',
    route: '/2022',
    label: 'partielle 2022',
    municipalities: villes2022
  },
  { id: '2021', route: '/2021', label: '2021', municipalities: villes2021 }
]
