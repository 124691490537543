<template>
  <div class="slide">
    <header>
      <div></div>
      <div class="header-title">
        <div class="header-title-text">{{ locationLabel }} - {{ title }}</div>
        <div class="header-title__underline"></div>
      </div>
      <div class="header-scrutin">
        <div class="header-scrutin__value header-title-text">{{ scrutin }}</div>
        <span class="header-scrutin__label">BOÎTES DE SCRUTIN</span>
      </div>
    </header>
    <div class="slide__body" :class="{ scroll }">
      <slot name="body"> body </slot>
    </div>
    <footer>
      <div class="footer-election">
        <div>{{ election }}</div>
        <div class="footer-election__non-officiel">RÉSULTATS NON-OFFICIELS</div>
      </div>
      <slot name="banner"></slot>
      <div class="footer-maj">
        <div class="footer-maj__text">
          DERNIÈRE MISE À JOUR DES RÉSULTATS —
          <div class="footer-maj__text__date">{{ majInfos }}</div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    locationLabel: {
      type: String,
      default: 'TITRE'
    },
    title: {
      type: String,
      default: 'TITRE'
    },
    location: {
      type: String,
      default: ''
    },
    scrutin: {
      type: String,
      default: '4/11'
    },
    election: {
      type: String,
      default: 'SCRUTIN DU 7 NOVEMBRE'
    },
    majInfos: {
      type: String,
      default: '2021-11-07 @ 18h22'
    },
    scroll: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    return {
      baseUrl: process.env.VUE_APP_API_URL.includes('storage.googleapis.com')
        ? `${process.env.VUE_APP_API_URL}/${props.location}/current`
        : `${process.env.VUE_APP_API_URL}/${props.location}`
    }
  }
}
</script>

<style lang="scss" scoped>
.header-title-text {
  font-family: 'SF Pro Black';
  font-size: 2.25rem;
  @media (max-height: 1024px) {
  }
  @media (max-height: 899px) {
    font-size: 1.75rem;
  }
  font-weight: 900;
  text-transform: uppercase;
}

.slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding-top: 3.25rem;
  height: calc(100vh - 3.25rem);
  @media (max-height: 1024px) {
    padding-top: 4.938rem;
    height: calc(100vh - 4.938rem);
  }
  @media (max-height: 899px) {
    padding-top: 2.5rem;
    height: calc(100vh - 2.5rem);
  }
  header {
    display: flex;
    justify-content: space-between;
    z-index: 1000;
    background-color: #f0f0f0;

    height: 3.6rem;
    padding: 0 6.25rem;
    @media (max-height: 1024px) {
      height: 3.1rem;
      padding: 0 5.625rem;
    }
    @media (max-height: 899px) {
      height: 2.5rem;
      padding: 0 2.5rem;
    }
    @media (min-aspect-ratio: 2/1) {
      width: 80vw;
      margin: auto;
    }
    .header-logo {
      height: 3.55rem;
      @media (max-height: 1024px) {
      }
      @media (max-height: 899px) {
        height: 2.5rem;
      }
    }
    .header-title {
      display: flex;
      flex-direction: column;
      font-size: 2.25rem;
      font-weight: bold;
      &__underline {
        height: 0.625rem;
        background-color: #c2c2c2;
      }
    }
    .header-scrutin {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      &__label {
        font-family: 'SF Pro Medium';
        font-size: 0.875rem;
        @media (max-height: 1024px) {
        }
        @media (max-height: 899px) {
          font-size: 0.75rem;
        }
        line-height: 1.16;
        font-weight: 500;
      }
    }
  }
  &__body {
    position: relative;
    display: flex;
    height: 100%;
    margin: 0 6.25rem;
    align-items: center;
    @media (max-height: 1024px) {
      margin: 0 5.625rem;
    }
    @media (max-height: 899px) {
      margin: 0 2.5rem;
    }
    @media (min-aspect-ratio: 2/1) {
      width: 80vw;
      margin: auto;
    }
    &.scroll {
      overflow-y: scroll;
    }
  }
  footer {
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 6.25rem;
    .footer-election {
      width: 13.125rem;
      height: 100%;
      padding: 0 1.75rem;
      box-shadow: 1.125rem 1.125rem 3.375rem -1.5625rem rgba(0, 0, 0, 0.52);
      background-color: var(--white);
      text-align: left;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: 'SF Pro Medium';
      font-size: 0.875rem;
      @media (max-height: 1024px) {
      }
      @media (max-height: 899px) {
        font-size: 0.75rem;
      }
      font-weight: bold;
      line-height: 1.14;

      &__non-officiel {
        color: #c2c2c2;
      }
    }

    .footer-maj {
      width: 11.25rem;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 1.75rem;
      box-shadow: -1.125rem 1.125rem 3.375rem -1.5625rem rgba(0, 0, 0, 0.52);
      background-color: var(--white);
      &__text {
        font-family: 'SF Pro Medium';
        font-size: 0.875rem;
        @media (max-height: 1024px) {
        }
        @media (max-height: 899px) {
          font-size: 0.75rem;
        }
        font-weight: 500;
        line-height: 1.14;
        text-align: right;
        &__date {
          font-weight: bold;
          color: #2c9a8b;
        }
      }
    }
  }
}
// }
</style>
