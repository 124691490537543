import _ from 'lodash'
import {
  getMairieTop3,
  getDistrictLabel,
  getTotalVotes,
  getCandidatColor,
  round4Decimal
} from '@/lib/dataTransformers/dataTransformersHelper.js'
import { getCandidatById, mairieCandidatesBlackOrWhite } from './utils'

function mairieDistrictResultToChartElement(top3, districtResult, params) {
  const districtLabel = getDistrictLabel(params, districtResult.district_id)
  const totalVotes = getTotalVotes(
    districtResult.resultats_candidats,
    districtResult.rejetes
  )

  const otherCandidates = districtResult.resultats_candidats.filter(
    item => !top3.includes(item.candidat_id)
  )
  const candidatsElements = top3.map(candidatId => {
    const candidatResult = districtResult.resultats_candidats.find(
      r => r.candidat_id === candidatId
    )
    const color = getCandidatColor(params, candidatId)
    const value = round4Decimal(
      (candidatResult.votes_quantite / totalVotes) * 100
    )
    return { candidat_id: candidatResult.candidat_id, color, value }
  })
  const sumOthers = otherCandidates.reduce(
    (acc, current) => acc + current.votes_quantite,
    0
  )

  const othersElements = {
    color: '#808080',
    value: round4Decimal((sumOthers / totalVotes) * 100)
  }
  const rejetesElement = {
    color: 'lightgrey',
    value: round4Decimal((districtResult.rejetes / totalVotes) * 100)
  }
  return {
    label: districtLabel,
    totalVotes,
    values: [...candidatsElements, othersElements, rejetesElement]
  }
}

export default (results, params) => () => {
  // do no display slide if no color for candidates
  if (mairieCandidatesBlackOrWhite(params)) return false

  const mairiefilter = results.resultats_postes_districts.filter(
    elem => elem.poste_id === 1
  )
  const top3 = getMairieTop3(results).map(item => item.candidat_id)
  const chartData = mairiefilter.map(result =>
    mairieDistrictResultToChartElement(top3, result, params)
  )

  const listofnames = top3.map(candidatId => {
    const candidat = getCandidatById(params, candidatId)
    return {
      name: candidat.candidat_label,
      color: candidat.candidat_couleur
    }
  })
  const legendeRejetes = { name: 'Rejetés', color: 'D3D3D3' }
  const legendeOthers = { name: 'Autres', color: '808080' }

  const chartDataChunks =
    chartData.length > 10 ? _.chunk(chartData, 8) : [chartData]
  const slides = chartDataChunks.map((chartData, index) => {
    const slideContent = {
      type: 's7',
      listofnames:
        mairiefilter[0].resultats_candidats.length > 3
          ? [...listofnames, legendeOthers, legendeRejetes]
          : [...listofnames, legendeRejetes],
      chartData
    }
    const pagination =
      chartDataChunks.length > 1 ? `${index + 1}/${chartDataChunks.length}` : ''
    const title = `${params.postes[0].poste_label}: DISTRICTS ${pagination}`
    return {
      type: 's7',
      description: 'S7: Mairie — votes dépouillées et validés par district',
      template: 's7Votes',
      data: { slideContent, title }
    }
  })
  return slides
}
