export default participation => () => {
  const tauxParticipation = participation.repartition_ages_sexes.map(
    partici => {
      return {
        categoage: partici.age_label.replace('<', ''),
        nbhomme: partici.quantite_hommes,
        nbfemme: partici.quantite_femmes
      }
    }
  )
  const slideContent = {
    type: 's12',
    yaxisname: 'NOMBRE D’ÉLECTEURS',
    colorone: '#e5714f',
    colortwo: '#1f74c1',
    valueFormatter: val => val,
    percent: false,
    inscrits: tauxParticipation
  }
  const title = 'L’ÉLECTION EN CHIFFRES'
  return {
    type: 's12',
    description: 'S12: Graphique: Âge et sexe des électeurs',
    template: 'ElectionSlide12',
    data: { slideContent, title }
  }
}
