<template>
  <div
    class="candidats-slide"
    :class="{ 'two-candidates': data.candidats.length === 2 }"
  >
    <div
      v-for="(item, index) in data.candidats"
      :key="index"
      class="candidats-slide__candidat"
    >
      <div
        class="candidats-slide__candidat__color"
        :class="`candidats-slide__candidat__color-${index + 1}`"
      >
        <component
          :is="`shape-${index + 1}`"
          v-if="hasColor(item)"
          :color="item.color"
        />
      </div>
      <div class="candidats-slide__candidat__content">
        <candidat
          :name="item.name"
          :votes="item.votes"
          :taux="item.taux"
          :image="item.image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Candidat from './candidatSlide/candidat.vue'
import Shape1 from './candidatSlide/shape-1.vue'
import Shape2 from './candidatSlide/shape-2.vue'
import Shape3 from './candidatSlide/shape-3.vue'
const defaultData = {
  candidats: [
    {
      image: 'http://localhost:8080/json-test/010102.jpg',
      name: 'Prénom Nom-1',
      votes: '12 345',
      taux: '61,14'
    },
    {
      image: 'http://localhost:8080/json-test/010102.jpg',
      name: 'Prénom Nom-1',
      votes: '12 345',
      taux: '61,14'
    },
    {
      image: 'http://localhost:8080/json-test/010102.jpg',
      name: 'Prénom Nom-1',
      votes: '12 345',
      taux: '61,14'
    }
  ]
}
export default {
  components: { Candidat, Shape1, Shape2, Shape3 },
  props: {
    data: {
      type: Object,
      default: () => defaultData
    }
  },
  setup(orops) {
    const hasColor = item => {
      return item.color !== '#000000' && item.color !== '#FFFFFF'
    }
    return { hasColor }
  }
}
</script>

<style lang="scss" scoped>
.candidats-slide {
  display: flex;
  justify-content: space-between;
  width: 100%;
  &.two-candidates {
    justify-content: space-evenly;
  }
  // height: 100%;
  &__candidat {
    position: relative;
    &__content {
      position: relative;
      width: 28.125rem;
      height: 23.125rem;
      border-radius: 3.125rem;
      box-shadow: 0 2.375rem 3.75rem -3.125rem rgba(0, 0, 0, 0.52);
      background-color: white;
      z-index: 1000;
      @media (max-height: 1024px) {
        width: 25.313rem;
        height: 21.25rem;
      }
      @media (max-height: 899px) {
        width: 19.062rem;
        height: 15.625rem;
      }
    }
    &__color {
      @media (max-height: 899px) {
        // display: none;
      }
    }
    &__color-1 {
      position: absolute;
      top: 0rem;
      right: 4rem;
      z-index: 700;
    }
    &__color-2 {
      position: absolute;
      top: 1rem;
      left: 2rem;
      z-index: 700;
    }
    &__color-3 {
      position: absolute;
      top: 0rem;
      left: 3rem;
      z-index: 700;
    }
  }
  align-items: center;
}
</style>
