<template>
  <map-slide-container class="slide">
    <template #content>
      <div v-if="type === 's6'" class="slide__content">
        <div class="slide__content__container">
          <h3 class="title">
            Candidats en avance <br />
            <span>par district</span>
          </h3>
          <div
            v-for="(cand, index) in data.candidats"
            :key="index"
            class="candidat"
          >
            <div
              class="candidat__rectangle"
              :style="{ 'background-color': cand.color }"
            ></div>
            <div class="candidat__name">{{ cand.name }}</div>
          </div>
        </div>
      </div>
      <div v-if="type === 's8'" class="slide__content">
        <div class="slide__content__container">
          <div class="s8title">
            <div class="s8title__minititle">CANDIDAT</div>
            <div class="s8title__title">{{ data.candidats }}</div>
          </div>
        </div>
        <hr />
        <div class="slide__content__container">
          <h3 class="title">
            Taux de votes recueillis: mairie
            <br />
            <span>par district</span>
          </h3>
          <div
            v-for="(cand, index) in data.legendvalues"
            :key="index"
            class="participation"
          >
            <div
              class="participation__rectangles"
              :style="{ 'background-color': cand[2] }"
            ></div>
            <div class="participation__name">
              Entre {{ cand[0] }} % et {{ cand[1] }} %
            </div>
          </div>
        </div>
      </div>
      <div v-if="type === 's9'" class="slide__content">
        <div class="slide__content__container">
          <h3 class="title">
            Candidats en avance <br />
            <span>par section de vote</span>
          </h3>
          <div
            v-for="(cand, index) in data.candidats"
            :key="index"
            class="candidat"
          >
            <div
              class="candidat__rectangle"
              :style="{ 'background-color': cand.color }"
            ></div>
            <div class="candidat__name">{{ cand.name }}</div>
          </div>
        </div>
      </div>
      <div v-if="type === 's10'" class="slide__content">
        <div class="slide__content__container">
          <h3 class="title">
            Taux de participation <br />
            <span>par section de vote</span>
          </h3>
          <div
            v-for="(leg, index) in listoflegeneds10"
            :key="index"
            class="participation"
          >
            <div
              class="participation__rectangles"
              :style="{ 'background-color': leg.color }"
            ></div>
            <div class="participation__name">{{ leg.echelle }}</div>
          </div>
        </div>
      </div>
    </template>
    <template #map>
      <slot name="map" />
    </template>
  </map-slide-container>
</template>

<script>
import MapSlideContainer from '@/components/templates/MapSlideContainer'
import { ref } from '@vue/reactivity'
export default {
  components: {
    MapSlideContainer
  },
  props: {
    type: {
      type: String,
      default: null
    },
    data: {
      type: Object,
      default: () => null
    }
  },
  setup() {
    const listoflegeneds10 = ref([
      { echelle: 'Aucun résultat', color: '#CFCFCF' },
      { echelle: 'Faible', color: '#EDF3D1' },
      { echelle: 'Moyen', color: '#95CB82' },
      { echelle: 'Fort', color: '#309951' },
      { echelle: 'Très fort', color: '#076A38' }
    ])
    return { listoflegeneds10 }
  }
}
</script>

<style lang="scss" scoped>
.slide__content {
  &__container {
    padding: 0 1.75rem;
  }
  .title {
    font-family: 'SF Pro Bold';
    text-align: left;
    line-height: 1.04;
    letter-spacing: normal;
    margin-top: 2rem;
  }
  .s8title {
    font-family: 'SF Pro Bold';
    text-align: left;
    padding: 1.375rem 0;
    // padding-bottom: 1.875rem;
    &__minititle {
      font-family: 'Helvetica';
      font-size: 1rem;
    }
    &__title {
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  span {
    font-family: 'SF Pro Medium';
    font-weight: 500;
  }
  .candidat {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &__name {
      font-family: 'SF Pro Bold';
      margin-left: 0.913rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
    }
    &__rectangle {
      width: 1.25rem;
      height: 1.25rem;
      opacity: 0.9;
      border-radius: 0.4375rem;
    }
  }
  .participation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &__name {
      font-family: 'SF Pro Medium';
      margin-left: 0.913rem;
      font-size: 1.125rem;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
    }
    &__rectangles {
      width: 2.5rem;
      height: 1.25rem;
      opacity: 0.9;
      border-radius: 7px;
    }
  }
}
</style>
