import { getCandidatById } from './utils'
export default (results, params) => () => {
  const mairieDistricts = results.resultats_postes_districts.filter(res =>
    res.poste_label.includes('Maire')
  )
  const candidatsEnAvanceParDistrict = mairieDistricts.map(district => {
    const resultatCandidatEnAvance = district.resultats_candidats.find(
      candidat => candidat.avance !== 0
    )
    if (!resultatCandidatEnAvance) {
      return {
        district_id: district.district_id
      }
    }
    const candidatId = resultatCandidatEnAvance.candidat_id
    const candidat = getCandidatById(params, candidatId)
    return {
      district_id: district.district_id,
      candidat_id: candidat.candidat_id,
      candidat_label: candidat.candidat_label,
      color: `#${candidat.candidat_couleur}`
    }
  })
  const distinctCandidats = candidatsEnAvanceParDistrict.reduce(
    (candidats, current) => {
      if (
        candidats.find(candidat => candidat.candidat_id === current.candidat_id)
      ) {
        return candidats
      }
      const candidat = {
        candidat_id: current.candidat_id,
        color: current.color,
        name: current.candidat_label
      }
      return [...candidats, candidat]
    },
    []
  )

  const mapContent = {
    candidatsEnAvanceParDistrict
  }
  const slideContent = {
    type: 's6',
    candidats: distinctCandidats
  }

  const title = 'Mairie: Candidats'
  return {
    type: 's6',
    description: 'S6: Mairie — candidat en avance par district',
    template: 'MapSlide',
    data: { slideContent, title, mapContent }
  }
}
