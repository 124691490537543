import * as d3Scales from 'd3-scale'
import { extent } from 'd3-array'

/**
 *
 * @typedef {Object} ParticipationSections
 * @property {Number} section_id
 * @property {Number} taux_participation
 */

/**
 * construit la choroplèthe de la participation par section (S10)
 * @param {Array.<ParticipationSections>} participationSections
 * @param {Array.<String>} colors array des couleurs
 * @param {String} scaleType echelle à utiliser ('quantile' ou 'quantize')
 */
export function participationSectionsChoropleth(
  participationSections,
  colors,
  defaultColor,
  scaleType,
  breakpoints
) {
  const rates = participationSections
    .filter(i => i.taux_participation !== 0) // if rate is 0, it means no value
    .map(i => i.taux_participation)
  const scales = ScaleFactory(rates, colors, breakpoints)
  const scale = scales[scaleType]()
  return participationSections.map(s => ({
    section_id: s.section_id,
    color:
      s.taux_participation === 0 ? defaultColor : scale(s.taux_participation)
  }))
}

export function candidatDistrictsChoropleth(
  tauxvotesCandidat,
  colors,
  defaultColor,
  scaleType,
  breakpoints
) {
  const rates = tauxvotesCandidat.map(i => i.votes_taux)
  const scales = ScaleFactory(rates, colors, breakpoints)
  const scale = scales[scaleType]()
  return tauxvotesCandidat.map(s => ({
    District: s.district_id,
    color: s.votes_taux === 0 ? defaultColor : scale(s.votes_taux)
  }))
}

export function buildDomainSteps(palette, domain) {
  const colors = palette
  const scale = d3Scales
    .scaleQuantize()
    .domain(domain)
    .range(colors)
  const nbDecimal = domain[1] - domain[0] > 2 ? 0 : 1
  const entries = colors.map(c => {
    const min = scale.invertExtent(c)[0]
    const max = scale.invertExtent(c)[1]
    return [min.toFixed(nbDecimal), max.toFixed(nbDecimal), c]
  })
  return entries
}

function ScaleFactory(values, colors, breakpoints = []) {
  return {
    quantize: () =>
      d3Scales
        .scaleQuantize()
        .domain(extent(values))
        .range(colors),
    quantile: () =>
      d3Scales
        .scaleQuantile()
        .domain(values)
        .range(colors),
    breakpoints: () => value => {
      const mins = [0, ...breakpoints]
      const color = mins.reduce((previous, current, index) => {
        return value >= current ? colors[index] : previous
      }, null)
      return color
    }
  }
}
