<template>
  <div class="candidats-slide">
    <div class="candidats-slide__rectangle">
      <div
        v-for="list in data.listofnames"
        :key="list"
        class="candidats-slide__rectangle__rec2"
      >
        <div
          class="candidats-slide__rectangle__rec2__carre"
          :style="{ backgroundColor: `#${list.color}` }"
        ></div>
        <div>{{ list.name }}</div>
      </div>
    </div>
    <div class="candidats-slide__graph">
      <div class="candidats-slide__graph__chartstyle">
        <css-chart :items="data.chartData" />
      </div>
    </div>
  </div>
</template>

<script>
import CssChart from '@/components/CssStackedBarChart.vue'
export default {
  components: {
    CssChart
  },
  props: {
    data: {
      type: Object,
      default: () => null
    }
  }
}
</script>

<style lang="scss" scoped>
.candidats-slide {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  @media (max-height: 899px) {
    margin-top: 5rem;
  }
  // height: 100%;
  &__rectangle {
    display: flex;
    justify-content: space-evenly;
    font-family: 'SF Pro Bold';
    font-size: 1.5rem;
    @media (max-height: 899px) {
      font-size: 1rem;
    }
    width: 100%;
    height: 5rem;
    margin-top: 2rem;
    // padding: 1.25rem 21.75rem;
    border-radius: 4px;
    box-shadow: 0 2rem 5rem -2rem rgba(0, 0, 0, 0.52);
    background-color: white;
    &__rec2 {
      display: flex;
      align-items: center;

      &__carre {
        height: 2.5rem;
        width: 2.5rem;
        margin-right: 1.375rem;
        border-radius: 0.5625rem;
      }
    }
  }
  &__graph {
    margin-top: 1.5rem;
    width: 100%;
    height: 28.625rem;
    &__chartstyle {
      height: 95%;
    }
  }
}
</style>
