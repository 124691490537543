<template>
  <div class="config">
    {{ type }}
    <div class="controls">
      <div
        @click="$emit('skip', -1)"
        @mouseover="addMessage('diapositive précédente')"
        @mouseleave="addMessage('')"
      >
        <i class="fas fa-backward" />
      </div>
      <div
        @click="$emit('update:paused', !paused)"
        @mouseover="
          addMessage(paused ? 'reprendre diaporama' : 'arrêter le diaporama')
        "
        @mouseleave="addMessage('')"
      >
        <i v-if="paused" class="fas fa-play" />
        <i v-else class="fas fa-pause" />
      </div>
      <div
        @click="$emit('skip', 1)"
        @mouseover="addMessage('diapositive suivante')"
        @mouseleave="addMessage('')"
      >
        <i class="fas fa-forward" />
      </div>
    </div>
    <div class="message">{{ message }}</div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
  props: {
    type: {
      type: String,
      default: '[type]'
    },
    duration: {
      type: Number,
      default: 0
    },
    paused: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:paused', 'skip'],
  setup() {
    const message = ref('')
    const addMessage = content => {
      message.value = content
    }
    return {
      message,
      addMessage
    }
  }
}
</script>

<style lang="scss" scoped>
.config {
  text-align: center;
  position: absolute;
  // background: white;
  max-width: 8rem;
  right: 2rem;
  bottom: 6rem;
  // border: 1px black solid;
  padding: 1rem;
  z-index: 10000;
  min-width: 12rem;
  .controls {
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 1rem 0;
  }
  .message {
    font-family: 'SF Pro Medium';
    color: grey;
    height: 1rem;
  }
}
</style>
