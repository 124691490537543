import axios from 'axios'
import { getElectionsForMunicipality } from '@/lib/elections-helper'
function getBaseUrl(municipality, election, elections) {
  const dir = process.env.VUE_APP_API_URL.includes('storage.googleapis.com')
    ? 'current'
    : ''
  if (elections.includes(election)) {
    return `/final-data-${election}/${municipality}`
  }
  if (!election) {
    return elections[0] === 'current'
      ? `${process.env.VUE_APP_API_URL}/${municipality}/${dir}`
      : `/final-data-${elections[0]}/${municipality}`
  }
  throw new Error('Election invalide, veuillez voter à nouveau')
}
export async function getDataFile(fileType, municipality, election, params) {
  const elections = getElectionsForMunicipality(municipality)

  const baseUrl = getBaseUrl(municipality, election, elections)
  try {
    const ts = new Date().getTime()
    const result = await axios.get(`${baseUrl}/${fileType}.json?${ts}`)
    return result.data
  } catch (error) {
    console.log(error) // TODO send error information somewhere maybe ?
    return null
  }
}
