<template>
  <div class="chart-slide">
    <div class="chart-slide__content">
      chart
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.chart-slide {
  display: flex;
  height: 600px;
  &__content {
    width: 1400px;
    border: 1px solid black;
  }
  justify-content: space-between;
}
</style>
