<template>
  <div class="css-chart">
    <div class="line">
      <div class="line__label"></div>
      <div class="value-scale">
        <div
          v-for="(item, index) in scaleValues"
          :key="index"
          class="value-scale__box"
          :class="{ 'is-first': index === 0 }"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div v-for="item in items" :key="item.label" class="line">
      <div class="line__label">{{ item.label }}</div>
      <div class="line__bar-container">
        <div
          v-for="(v, i) in item.values"
          :key="v.color"
          class="bar"
          :class="{
            'is-first': i === 0,
            'is-last': i === item.values.length - 1
          }"
          :style="{ width: `${v.value}%`, backgroundColor: v.color }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
const districtsFake = [
  {
    label: 'District Numéro 1',
    values: [
      { color: 'blue', value: 50 },
      { color: 'green', value: 20 },
      { color: 'red', value: 15 },
      { color: 'grey', value: 10 },
      { color: 'lightgrey', value: 5 }
    ]
  },
  {
    label: 'District Numéro 1',
    values: [
      { color: 'blue', value: 50 },
      { color: 'green', value: 20 },
      { color: 'red', value: 15 },
      { color: 'grey', value: 10 },
      { color: 'lightgrey', value: 5 }
    ]
  },
  {
    label: 'District Numéro 1',
    values: [
      { color: 'blue', value: 50 },
      { color: 'green', value: 20 },
      { color: 'red', value: 15 },
      { color: 'grey', value: 10 },
      { color: 'lightgrey', value: 5 }
    ]
  },
  {
    label: 'District Numéro 1',
    values: [
      { color: 'blue', value: 50 },
      { color: 'green', value: 20 },
      { color: 'red', value: 15 },
      { color: 'grey', value: 10 },
      { color: 'lightgrey', value: 5 }
    ]
  }
]
export default {
  props: {
    items: {
      type: Array,
      default: districtsFake
    }
  },
  setup() {
    return {
      scaleValues: _.range(10).map(i => (i + 1) * 10 + '%')
    }
  }
}
</script>

<style lang="scss" scoped>
.css-chart {
  font-family: 'SF Pro Bold';
  // position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .line {
    display: flex;
    height: 3rem;
    align-items: center;
    justify-content: space-between;
    .value-scale {
      font-size: 0.938rem;
      color: #c2c2c2;
      width: 75%;
      display: flex;
      justify-content: space-between;
      &__box {
        width: 10%;
        border-right: 0.1rem #c2c2c2 solid;
        text-align: right;
        padding-right: 0.5rem;
        &.is-first {
          border-left: 0.1rem #c2c2c2 solid;
        }
      }
    }
    &__label {
      font-size: 1.5rem;
      width: 25%;
      text-align: left;
    }
    &__bar-container {
      font-family: 'SF Pro SemiBold';
      font-size: 1.125rem;
      font-weight: bold;
      width: 75%;
      height: 2.75rem;
      display: flex;
      align-items: center;
      .bar {
        height: 2.125rem;
        &.is-first {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
        &.is-last {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
      .value {
        padding-left: 1.125rem;
      }
    }
  }
}
</style>
