<template>
  <div>
    <svg
      viewBox="0 0 409 428"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 59.1 (86144) - https://sketch.com -->
      <title>Path</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Responsive"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="mairie_desktop_1440"
          transform="translate(-526.000000, -294.000000)"
          :fill="color"
        >
          <path
            id="Path"
            d="M713.861555,753.798884 C676.978545,748.452281 647.66834,724.25974 625.930939,681.221264 C605.603455,640.974318 586.935218,599.016456 572.133114,578.446572 C559.738234,561.221911 535.575533,540.613677 523.697502,529.56961 C511.819471,518.525542 496.817897,483.630638 496.817897,451.691141 C496.817897,430.398144 502.194038,410.742647 512.946319,392.72465 C546.597994,342.881376 605.277553,310.207146 688.984996,294.701961 C772.692439,279.196775 838.321676,309.887993 885.872705,386.775614 C900.419705,419.615092 906.122475,450.549144 902.981015,479.577771 C899.839555,508.606398 894.960466,528.20862 888.343749,538.384439 C879.81075,551.348533 870.694781,561.803585 860.99584,569.749593 C846.44743,581.668605 827.057072,614.236803 823.652335,625.167148 C820.247598,636.097493 806.742893,698.20583 775.359793,729.659838 C769.282022,735.751341 756.008039,746.448888 749.458969,748.922632 C740.84589,752.173467 728.980085,753.798884 713.861555,753.798884 Z"
            transform="translate(700.317897, 522.298884) rotate(-315.000000) translate(-700.317897, -522.298884) "
          ></path>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'green'
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  width: 27.45rem;
  height: 28.68rem;
  @media (max-height: 1024px) {
  }
  @media (max-height: 899px) {
    width: 17rem;
    height: 21rem;
  }
}
</style>
