<template>
  <div>
    <svg
      viewBox="0 0 397 420"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 59.1 (86144) - https://sketch.com -->
      <title>Path 3</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Responsive"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="mairie_desktop_1440"
          transform="translate(-978.000000, -291.000000)"
          :fill="color"
        >
          <path
            id="Path-3"
            d="M1007.19141,499.41391 C1064.49232,567.996265 1102.09327,610.148846 1119.99426,625.871653 C1146.84575,649.455863 1195.71522,691.994092 1250.47727,697.999062 C1286.9853,702.002375 1316.66822,694.326534 1339.52604,674.971539 C1366.16394,648.418326 1380.98284,605.837337 1383.98272,547.228573 C1386.98261,488.619809 1386.98261,453.647861 1383.98272,442.312727 C1374.66578,404.274882 1358.99041,376.601005 1336.95661,359.291096 C1314.92281,341.981187 1300.81752,331.912498 1294.64071,329.085028 C1269.36181,314.222205 1240.4635,305.867199 1207.9458,304.020011 C1175.42809,302.172823 1141.0208,302.905936 1104.72392,306.219351 C1062.84892,312.876279 1033.7122,322.329358 1017.31374,334.578587 C992.716056,352.952432 975.901796,375.021941 971.894105,387.462081 C967.886415,399.902221 963.798828,425.81574 971.894105,445.447271 C977.290957,458.534959 989.056726,476.523838 1007.19141,499.41391 Z"
            transform="translate(1176.732638, 501.028236) rotate(-270.000000) translate(-1176.732638, -501.028236) "
          ></path>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'green'
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  width: 27.45rem;
  height: 28.68rem;
  @media (max-height: 1024px) {
  }
  @media (max-height: 899px) {
    width: 17rem;
    height: 21rem;
  }
}
</style>
