import { displayNum } from './utils'

const voteTypesParams = [
  {
    id: 'BVO',
    label: 'Ordinaire (BVO)',
    color: '#001219'
  },
  {
    id: 'BVA',
    label: 'Par anticipation (BVA)',
    color: '#005f73'
  },
  {
    id: 'BVI',
    label: 'Itinérant (BVI)',
    color: '#0a9396'
  },
  {
    id: 'BVC',
    label: 'Correspondance (BVC)',
    color: '#94d2bd'
  },
  {
    id: 'BVP',
    label: 'Présidence (BVP)',
    color: '#e9d8a6'
  },
  {
    id: 'BVD',
    label: 'À domicile (BVD)',
    color: '#ee9b00'
  }
]
export default participation => () => {
  const voteTypesResults = participation.repartition_vote_types
  const voteTypes = voteTypesParams
    .map((voteType, index) => {
      const result = voteTypesResults.find(
        v => v.vote_type_label === voteType.id
      )
      if (!result) return null
      return {
        label: voteType.label,
        value: result.vote_type_taux,
        valueLabel: displayNum(result.vote_type_taux) + ' %',
        color: voteType.color
      }
    })
    .filter(Boolean)
  const slideContent = {
    type: 's11',
    voteTypes
  }
  const title = 'L’ÉLECTION EN CHIFFRES'
  return {
    type: 's11',
    description:
      'S11: Graphique: Types de votes utilisés pour les votes dépouillés',
    template: 's11Votes',
    data: { slideContent, title }
  }
}
