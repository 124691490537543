import electionsConfig from '../config/elections'
import slugify from 'slugify'

function slugifyMunicipality(municipality) {
  return municipality === 'La Prairie'
    ? 'laprairie'
    : slugify(municipality, {
        lower: true,
        strict: false,
        locale: 'vi',
        trim: true
      })
}

export function getElectionsForMunicipality(municipality) {
  return electionsConfig
    .filter(e => {
      const slugifiedElectionsMunicipalities = e.municipalities.map(m =>
        slugifyMunicipality(m)
      )
      return slugifiedElectionsMunicipalities.includes(
        slugifyMunicipality(municipality)
      )
    })
    .map(e => e.id)
}

function generateLink(municipality, electionId) {
  const election = electionsConfig.find(e => e.id === electionId)

  return `
          <a style="margin:4px" href="/villes/${slugifyMunicipality(
            municipality
          )}${
    election.route
  }" target="_blank" >Voir le tableau de bord (élection ${election.label})
          </a>
          `
}
export function generateLinks(municipality) {
  const electionIds = getElectionsForMunicipality(municipality)
  return electionIds
    .map(electionId => generateLink(municipality, electionId))
    .join(' ')
}

export function getLastElectionApiUrl() {}
