export default participation => () => {
  const tauxParticipation = participation.participation_groupes_age.map(
    partici => {
      return {
        categoage: partici.age_label.replace('<', ''),
        pourcentage: partici.age_taux.toFixed(1)
      }
    }
  )
  const slideContent = {
    type: 's13',
    yaxisname: '% PARTICIPATION',
    color: '#2d9d8e',
    valueFormatter: val => (val === 0 ? val : val + '%'),
    percent: true,
    inscrits: tauxParticipation
    // inscrits: [
    //   {
    //     categoage: '18-19',
    //     pourcentage: 39
    //   },
    //   {
    //     categoage: '20-24',
    //     pourcentage: 22
    //   },
    //   {
    //     categoage: '25-29',
    //     pourcentage: 20
    //   },
    //   {
    //     categoage: '30-34',
    //     pourcentage: 30
    //   },
    //   {
    //     categoage: '35-39',
    //     pourcentage: 35
    //   },
    //   {
    //     categoage: '40-44',
    //     pourcentage: 42
    //   },
    //   {
    //     categoage: '45-49',
    //     pourcentage: 43
    //   },
    //   {
    //     categoage: '50-54',
    //     pourcentage: 56
    //   },
    //   {
    //     categoage: '55-59',
    //     pourcentage: 58
    //   },
    //   {
    //     categoage: '60-64',
    //     pourcentage: 61
    //   },
    //   {
    //     categoage: '65-69',
    //     pourcentage: 65
    //   },
    //   {
    //     categoage: '70-74',
    //     pourcentage: 63
    //   },
    //   {
    //     categoage: '75-79',
    //     pourcentage: 63
    //   },
    //   {
    //     categoage: '80-84',
    //     pourcentage: 50
    //   },
    //   {
    //     categoage: '85-89',
    //     pourcentage: 41
    //   },
    //   {
    //     categoage: '90-94',
    //     pourcentage: 37
    //   },
    //   {
    //     categoage: '95-100',
    //     pourcentage: 18
    //   }
    // ]
  }
  const title = 'L’ÉLECTION EN CHIFFRES'
  return {
    type: 's13',
    description:
      'S13: Graphique: participation aux tables d’accueil selon l’âge',
    template: 'ElectionSlide13',
    data: { slideContent, title }
  }
}
